import { uuid } from "uuidv4";

export const whatWeDoContent = {
  main: [
    {
      id: uuid(),
      type: "text",
      content:
        "The service that the company delivers has two elements, financial planning and investment management.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Financial planning involves working with one of the company’s advisers to identify the goals that are important to you, and then building a detailed financial plan for meeting them. These goals could be planning for retirement, providing financial security for your family or just getting your money working harder for you.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Circumstances change and our financial advisers therefore work with our clients on an ongoing basis to make sure that their finances are on track to meet their goals.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Our advisers ensure that our clients are making use of their tax allowances and reliefs in the most efficient manner, and assist them in navigating the ever changing regulatory and legislative landscape governing pensions and inheritance tax.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "The engine that powers our clients’ financial plans are the company’s investment management services.  Our Managed Portfolio Service and Bespoke Portfolio Services brochures provide more information on the firm’s investment management services and can be found on our website.",
    },
  ],
  truncated: [
    {
      id: uuid(),
      type: "text",
      content:
        "Financial services is no different to any other industry in that it is constantly changing.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "At Throgmorton we have always sought to be at the vanguard of change in our industry and work tirelessly to develop new ways to improve the service that we offer our clients.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "We started the firm to do things differently, and offer our clients a complete wealth management service, providing both financial planning and investment management under one roof.",
    },
  ],
};
