import React from "react";
import { useState } from "react";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiArrowLeftSLine,
} from "react-icons/ri";

export default function HomeArrow({
  type,
  next,
  previous,
  color,
  slideDown,
  slideUp,
  index,
  action,
}) {
  const [animate, shouldanimate] = useState(false);

  switch (type) {
    case "UP":
      return (
        <div className="home-arrow">
          <RiArrowUpSLine
            className={`home-arrow-icon up-arrow-icon ${color} ${
              animate
                ? "animate__animated animate__pulse animate__infinite animate__slow"
                : ""
            }`}
            size="2rem"
            onClick={() => {
              slideUp(index);
            }}
            onMouseEnter={() => {
              shouldanimate(true);
            }}
            onMouseLeave={() => {
              shouldanimate(false);
            }}
            color={`var(--primary-${color}`}
          />
        </div>
      );
    case "DOWN":
      return (
        <div className="home-arrow">
          <RiArrowDownSLine
            className={`home-arrow-icon down-arrow-icon ${color} ${
              animate
                ? "animate__animated animate__pulse animate__infinite animate__slow"
                : ""
            }`}
            size="2rem"
            onClick={() => {
              slideDown(index);
            }}
            onMouseEnter={() => {
              shouldanimate(true);
            }}
            onMouseLeave={() => {
              shouldanimate(false);
            }}
            color={`var(--primary-${color}`}
          />
        </div>
      );
    case "RIGHT":
      return (
        <div className="home-arrow">
          <RiArrowRightSLine
            className={`home-arrow-icon right-arrow-icon ${color} ${
              animate
                ? "animate__animated animate__pulse animate__infinite animate__slow"
                : ""
            }`}
            size="2rem"
            onClick={() => {
              action();
            }}
            onMouseEnter={() => {
              shouldanimate(true);
            }}
            onMouseLeave={() => {
              shouldanimate(false);
            }}
            color={`var(--primary-${color}`}
          />
        </div>
      );
    case "LEFT":
      return (
        <div className="home-arrow">
          <RiArrowLeftSLine
            className={`home-arrow-icon left-arrow-icon ${color} ${
              animate
                ? "animate__animated animate__pulse animate__infinite animate__slow"
                : ""
            }`}
            size="2rem"
            onClick={() => {
              action();
            }}
            onMouseEnter={() => {
              shouldanimate(true);
            }}
            onMouseLeave={() => {
              shouldanimate(false);
            }}
            color={`var(--primary-${color}`}
          />
        </div>
      );
    default:
      return null;
  }
}
