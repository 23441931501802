import React from "react";
import { IoMdCloseCircle } from "react-icons/io";

export default function FilePopup({ fileSrc, showFilePopup, type = "iframe" }) {
  return (
    <div className="pdfviewer-container animate__animated animate__fadeIn">
      <div
        onClick={() => {
          showFilePopup(false);
        }}
      >
        <IoMdCloseCircle className="close" />
      </div>
      {type === "iframe" && (
        <iframe
          title={fileSrc}
          className="pdfviewer"
          src={fileSrc}
          frameBorder="0"
        ></iframe>
      )}
      {type === "video" && <video src={fileSrc} controls />}
    </div>
  );
}
