import React from "react";
import { forwardRef } from "react";
import { createRef } from "react";
import { useRef } from "react";
import { useState } from "react";
import { BiCopy } from "react-icons/bi";
export default function DownloadLink({
  category,
  data,
  isMobile,
  setFileSrc,
  showFilePopup,
}) {
  const [showNotification, setShowNotification] = useState(false);

  const notificationRef = useRef();

  function buildUrl(data) {
    const path = `${window.location.origin}/literature?category=${category}&content=${data.document.url}`;
    return path;
  }

  function handleShowNotification() {
    setShowNotification(true);
    notificationRef.current.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 3000,
      fill: "forwards",
      easing: "ease-in-out",
    });
    let timer = setTimeout(() => {
      setShowNotification(false);
    }, 2001);

    clearTimeout(timer);
  }

  function copyUrlToClipboard(data) {
    navigator.clipboard
      .writeText(buildUrl(data))
      .then(() => {
        handleShowNotification();
      })
      .catch((e) => console.error(e));
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {!isMobile && (
        <BiCopy
          style={{ cursor: "pointer" }}
          title="Click to copy URL"
          color="white"
          onClick={(e) => {
            copyUrlToClipboard(data);
            e.currentTarget.animate(
              [
                {
                  transform: "scale(0.8)",
                },
                {
                  transform: "scale(1)",
                },
              ],
              { duration: 300 },
            );
          }}
        />
      )}
      <a
        style={{
          cursor: "pointer",
        }}
        onClick={(e) => {
          if (!isMobile) {
            e.preventDefault();
            setFileSrc(data.document.url);
            showFilePopup(true);
          }
        }}
        target="_blank"
        className="download-link"
        rel="noopener noreferrer"
        href={data?.document?.url}
      >
        {data.title}
      </a>
      {showNotification && <FloatingNotification ref={notificationRef} />}
    </div>
  );
}

const FloatingNotification = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 1,
        background: "white",
        margin: "1rem",
        borderRadius: "0.5rem",
        padding: "0.25rem 1rem",
      }}
    >
      <p
        style={{
          color: "#c2a044",
        }}
      >
        Link copied!
      </p>
    </div>
  );
});
