import React from "react";
import "./Notification.css";
export default function Notification(props) {
  return (
    <aside className="cookies-notification">
      <p>This site uses cookies to enhance your experience.</p>
      <button
        onClick={(e) => {
          props.setCookieAcceptance("true");
          window.localStorage.setItem("acceptedCookies", "true");
        }}
      >
        OK
      </button>
    </aside>
  );
}
