import { uuid } from "uuidv4";

export const ourStoryContent = {
  main: [
    {
      id: uuid(),
      type: "text",
      content:
        "Throgmorton was founded by a group of experienced financial planners and investment managers with the goal of building a company that would provide clients with a high-quality, joined-up wealth management service.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Most firms handle only the financial planning and outsource responsibility for managing your investments to another company.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "We feel that being able to deliver both services from under one roof allows us to serve our clients better. It reduces the costs they incur which over the long-term has a positive effect on the value of their investments.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "The company’s relentless focus on serving its clients, and on improving and expanding its service offering has led to it becoming a strong presence within wealth management. Its reputation allows it to attract and retain talented staff who in turn further improve its services.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "We believe that if the company continues to serve its clients well this virtuous process will continue, and we will be able to enrich the lives of a growing number of people through our services.",
    },
  ],
  truncated: [
    {
      id: uuid(),
      type: "text",
      content:
        "Throgmorton was founded by a group of experienced financial planners and investment managers with the goal of building a company that would provide clients with a high-quality, joined-up wealth management service.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Most firms handle only the financial planning and outsource responsibility for managing your investments to another company.",
    },
  ],
};
