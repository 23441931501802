import React, { useState, useEffect, useContext } from "react";
import "./Downloads_desk.css";
import "./Downloads_mob.css";
import Collapsible from "react-collapsible";
import { MobileContext } from "../App";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Modal from "react-modal";
import { BiCopy } from "react-icons/bi";
import PageHeader from "../wrappers/PageHeader";
import { downloadsImg } from "../data/imageData";
import FilePopup from "./FilePopup";
import { useLocation, useParams } from "react-router-dom";
import DownloadLink from "./DownloadLink";

Modal.setAppElement("#modal");

export default function Downloads() {
  let isMobile = useContext(MobileContext);

  const location = useLocation();

  const [factsheets, setFactsheets] = useState([{}]);
  const [brochures, setBrochures] = useState([]);
  const [resources, setResources] = useState([]);
  const [investmentCommentaries, setInvestmentCommentaries] = useState([]);
  const [brochuresIsOpen, openBrochures] = useState(false);
  const [factsheetsIsOpen, openFactsheets] = useState(false);

  const [investmentCommIsOpen, openInvestmentComm] = useState(false);
  const [resourcesIsOpen, openResources] = useState(false);
  const [fileSrc, setFileSrc] = useState("");
  const [filePopupVisible, showFilePopup] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.title = "Literature | Throgmorton Capital Management";
    Promise.all([
      fetch(
        "https://europe-west2-throgmortoncapital.cloudfunctions.net/getFactsheets",
      )
        .then((res) => res.json())
        .then((data) => {
          setFactsheets(data.factsheets);
        })
        .catch(console.error),
      fetch(
        "https://europe-west2-throgmortoncapital.cloudfunctions.net/getBrochures",
      )
        .then((res) => res.json())
        .then((data) => {
          setBrochures(data.brochures);
        })
        .catch(console.error),
      fetch(
        "https://europe-west2-throgmortoncapital.cloudfunctions.net/getResources",
      )
        .then((res) => res.json())
        .then((data) => {
          setResources(data.resources);
        })
        .catch(console.error),
      fetch(
        "https://europe-west2-throgmortoncapital.cloudfunctions.net/getInvestmentCommentaries",
      )
        .then((res) => res.json())
        .then((data) => {
          setInvestmentCommentaries(data);
        })
        .catch(console.error),
    ]).catch(console.error);
  }, []);

  useEffect(() => {
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    const category = searchParams.get("category");
    const content = searchParams.get("content");

    if (location.search && category && content && !isMobile) {
      switch (category) {
        case "brochures":
          if (brochures.length > 1) {
            openBrochures(true);
            setFileSrc(content);
            showFilePopup(true);
          }
          break;
        case "factsheets":
          if (factsheets.length > 0) {
            openFactsheets(true);
            setFileSrc(content);
            showFilePopup(true);
          }
          break;
        case "investmentCommentary":
          if (investmentCommentaries.length > 0) {
            openInvestmentComm(true);
            setFileSrc(content);
            showFilePopup(true);
          }
          break;
        case "resources":
          if (resources.length > 1) {
            openResources(true);
            setFileSrc(content);
            showFilePopup(true);
          }
          break;
        default:
          return;
      }
    }
  }, [
    investmentCommentaries,
    brochures,
    factsheets,
    resources,
    location.search,
  ]);

  useEffect(() => {
    if (isMobile) {
      setFileSrc("");
      showFilePopup(false);
    }
  }, [isMobile, setFileSrc, showFilePopup]);

  const url =
    "https://throgmortoncm.us10.list-manage.com/subscribe/post?u=223eea6ab52227fbc9a6aec1b&amp;id=71e85e48a3";

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      height: isMobile ? "85vh" : "auto",
      width: isMobile ? "auto" : "80%",
      zIndex: 99999,
      position: "absolute",
    },
  };

  function openModal() {
    window.document.body.style.overflowY = "hidden";
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be
  }

  function closeModal() {
    window.document.body.style.overflowY = "auto";
    setIsOpen(false);
  }

  function handleOpenBrochures() {
    openBrochures(!brochuresIsOpen);
  }
  function handleOpenFactsheets() {
    openFactsheets(!factsheetsIsOpen);
  }

  function handleOpenInvestmentComm() {
    openInvestmentComm(!investmentCommIsOpen);
  }
  function handleOpenResources() {
    openResources(!resourcesIsOpen);
  }

  return (
    <section className="page-section downloads-section" id="downloads">
      <div className="page-col-1">
        <img
          src={downloadsImg}
          alt=""
          className="page-img animate__animated animate__fadeIn animate__slow"
        />
        {isMobile ? <PageHeader title="Literature" /> : null}
      </div>
      <div className="page-col-2 downloads-col">
        {!isMobile ? (
          <h1 className="page-header animate__animated animate__fadeInDown animate__slow">
            Literature
          </h1>
        ) : null}
        {filePopupVisible && (
          <FilePopup fileSrc={fileSrc} showFilePopup={showFilePopup} />
        )}
        <Collapsible
          transitionTime={200}
          easing="ease"
          open={brochuresIsOpen}
          trigger={
            <button onClick={handleOpenBrochures} className="downloads-btn">
              Brochures{" "}
              <span className="expand-btn">{brochuresIsOpen ? "-" : "+"}</span>
            </button>
          }
        >
          {brochures
            .sort((a, b) => a - b)
            .map((data, i) => {
              return (
                <div key={data.id} className="download-links">
                  {!isMobile ? (
                    <DownloadLink
                      key={data.id}
                      category={"brochures"}
                      data={data}
                      isMobile={isMobile}
                      setFileSrc={setFileSrc}
                      showFilePopup={showFilePopup}
                    />
                  ) : (
                    <DownloadLink
                      key={data.id}
                      category={"brochures"}
                      data={data}
                      isMobile={isMobile}
                      setFileSrc={setFileSrc}
                      showFilePopup={showFilePopup}
                    />
                  )}
                </div>
              );
            })}
        </Collapsible>
        <Collapsible
          transitionTime={200}
          easing="ease"
          open={factsheetsIsOpen}
          trigger={
            <button onClick={handleOpenFactsheets} className="downloads-btn">
              Factsheets{" "}
              <span className="expand-btn">{factsheetsIsOpen ? "-" : "+"}</span>
            </button>
          }
        >
          {factsheets &&
            factsheets.map((data, i) => {
              return !isMobile ? (
                <div key={data?.id} className="download-links">
                  <DownloadLink
                    key={data.id}
                    category={"factsheets"}
                    data={data}
                    isMobile={isMobile}
                    setFileSrc={setFileSrc}
                    showFilePopup={showFilePopup}
                  />
                </div>
              ) : (
                <div key={data.id} className="download-links">
                  <DownloadLink
                    key={data.id}
                    category={"factsheets"}
                    data={data}
                    isMobile={isMobile}
                    setFileSrc={setFileSrc}
                    showFilePopup={showFilePopup}
                  />
                </div>
              );
            })}
        </Collapsible>

        <Collapsible
          transitionTime={200}
          easing="ease"
          trigger={
            <button
              className="downloads-btn"
              onClick={handleOpenInvestmentComm}
            >
              Investment Commentary{" "}
              <span className="expand-btn">
                {investmentCommIsOpen ? "-" : "+"}
              </span>
            </button>
          }
          open={investmentCommIsOpen}
        >
          <div className="download-links">
            {investmentCommentaries
              .sort((a, b) => b.year - a.year)
              .map((data, i) => {
                return (
                  <>
                    <h3 key={data.id} className="date-header">
                      {data.year}
                    </h3>
                    {data.documents
                      .sort((a, b) => b.order - a.order)
                      .map((doc, i) => {
                        return !isMobile ? (
                          <DownloadLink
                            key={doc.id}
                            category={"investmentCommentary"}
                            data={{
                              id: doc.id,
                              title: doc.title,
                              document: { url: doc.url },
                            }}
                            isMobile={isMobile}
                            setFileSrc={setFileSrc}
                            showFilePopup={showFilePopup}
                          />
                        ) : (
                          <DownloadLink
                            key={doc.id}
                            category={"investmentCommentary"}
                            data={{
                              id: doc.id,
                              title: doc.title,
                              document: { url: doc.url },
                            }}
                            isMobile={isMobile}
                            setFileSrc={setFileSrc}
                            showFilePopup={showFilePopup}
                          />
                        );
                      })}
                  </>
                );
              })}
          </div>
        </Collapsible>
        <Collapsible
          transitionTime={200}
          easing="ease"
          open={resourcesIsOpen}
          trigger={
            <button onClick={handleOpenResources} className="downloads-btn">
              Resources{" "}
              <span className="expand-btn">{resourcesIsOpen ? "-" : "+"}</span>
            </button>
          }
        >
          <div className="download-links">
            {resources.map((data, i) => {
              return !isMobile ? (
                <DownloadLink
                  key={data.id}
                  category={"resources"}
                  data={data}
                  isMobile={isMobile}
                  setFileSrc={setFileSrc}
                  showFilePopup={showFilePopup}
                />
              ) : (
                <DownloadLink
                  key={data.id}
                  category={"resources"}
                  data={data}
                  isMobile={isMobile}
                  setFileSrc={setFileSrc}
                  showFilePopup={showFilePopup}
                />
              );
            })}
          </div>
        </Collapsible>
        <div style={{ padding: "1rem 0" }}>
          <button className="subscribe-modal-btn" onClick={openModal}>
            Subscribe
          </button>
          <p className="sign-up-text">
            Sign up to receive our Investment Commentary by email.
          </p>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Subscribe Modal"
        >
          <div id="mc_embed_signup">
            <button className="close-subscribe-modal-btn" onClick={closeModal}>
              &#10005;
            </button>
            <h2 className="subscribe-header">
              Subscribe to our Investment Commentary
            </h2>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <SubscribeForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </Modal>
      </div>
    </section>
  );
}

const SubscribeForm = ({ status, message, onValidated }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [emailAdd, setEmailAdd] = useState(null);

  let email, fName, lName;
  const submit = () =>
    email &&
    fName &&
    lName &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      FNAME: fName.value,
      LNAME: lName.value,
    });

  return (
    <div className="subscribe-form-container">
      <input
        ref={(node) => (fName = node)}
        type="text"
        placeholder="First name*"
        className="subscribe-input firstName"
        required
        onChange={(e) => setFirstName(e.target.value)}
      />
      <input
        ref={(node) => (lName = node)}
        type="text"
        placeholder="Last name*"
        className="subscribe-input lastName"
        required
        onChange={(e) => setLastName(e.target.value)}
      />

      <input
        ref={(node) => (email = node)}
        type="email"
        placeholder="Email*"
        className="subscribe-input email-input"
        required
        onChange={(e) => setEmailAdd(e.target.value)}
      />
      <button
        disabled={!firstName || !lastName || !emailAdd}
        className="subscribe-btn"
        onClick={submit}
      >
        {!firstName || !lastName || !emailAdd
          ? "Please fill in all fields"
          : status === "sending"
          ? "Submitting..."
          : "Submit"}
      </button>

      {status === "error" && (
        <div
          className="subscribe-status-container error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="subscribe-status-container success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <p className="subscribe-disclaimer-text">
        By clicking submit you confirm that you are happy for us to store your
        personal data on our internal systems. We will only use your data for
        the purpose of sending you Investment Commentary. We will not share your
        data with any third parties for any other purpose. For further details
        on how we handle your data please see our{" "}
        <a style={{ color: "#c2a044" }} href="/privacy">
          privacy policy
        </a>
        . If you are no longer happy with us storing your data for this purpose
        you can click on the unsubscribe link in any email you receive.
      </p>
    </div>
  );
};
