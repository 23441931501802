import React, { useEffect, useContext, useState } from "react";
import "./Services_desk.css";
import "./Services_mob.css";
import "./Services_desk.css";
import { MobileContext } from "../App";
import PageHeader from "../wrappers/PageHeader";
import { investmentManagementImg } from "../data/imageData";
import HomeLink from "../wrappers/HomeLink";
import ImageCard from "../components/ImageCard";

import HomeArrow from "../wrappers/HomeArrow";

import FilePopup from "../downloads/FilePopup";
import HomePagination from "../wrappers/HomePagination";

export default function InvestmentManagement({
  content,
  isHome,
  isIntersected,
  index,
  slideDown,
  slideUp,
  imageCardsData,
  maxPages,
}) {
  let isMobile = useContext(MobileContext);

  const latestBespokeBrochure =
    "https://europe-west2-throgmortoncapital.cloudfunctions.net/getLatestBespokeBrochure";
  const latestManagedBrochure =
    "https://europe-west2-throgmortoncapital.cloudfunctions.net/getLatestManagedBrochure";

  const [isVisible, setIsVisible] = useState(false);
  const [filePopupVisible, showFilePopup] = useState(false);
  const [fileSrc, setFileSrc] = useState("");
  const [brochures, setBrochures] = useState([]);
  const [latestBespoke, setLatestBespoke] = useState("");
  const [latestManaged, setLatestManaged] = useState("");

  useEffect(() => {
    if (!isHome)
      document.title = "Investment Management | Throgmorton Capital Management";

    if (isIntersected === false && !isMobile) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    if (isMobile) {
      setIsVisible(true);
    }

    console.log(imageCardsData);
  }, [isHome, isIntersected, imageCardsData]);

  useEffect(() => {
    fetch(latestBespokeBrochure)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("in the fetch call", data);
          setLatestBespoke(data.brochures[0].document.url);
        }
      });
    fetch(latestManagedBrochure)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("in the fetch call", data);
          setLatestManaged(data.brochures[0].document.url);
        }
      });
  }, []);

  const handleShowPdf = (e, fileSrc) => {
    e.preventDefault();
    setFileSrc(fileSrc);
    showFilePopup(true);
  };

  return (
    <section
      data-index={index}
      id="investment-management"
      className={
        isHome
          ? "page-section services-section alternate-page-section"
          : "page-section services-section"
      }
    >
      <div className="page-col-1">
        <img
          data-index={index}
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
          className={
            isIntersected || !isHome
              ? "page-img animate__animated animate__fadeIn animate__slow"
              : "page-img"
          }
          src={investmentManagementImg}
          alt=""
        />
        {isMobile ? <PageHeader title="Investment Management" /> : null}
      </div>
      <article data-index={index} className="page-col-2">
        {!isMobile ? (
          <h1
            onAnimationStart={() => {
              setIsVisible(true);
            }}
            style={{
              visibility: isVisible ? "visible" : "hidden",
            }}
            className={
              isIntersected || !isHome
                ? "page-header animate__animated animate__fadeInDown animate__slow"
                : "page-header"
            }
          >
            Investment Management
          </h1>
        ) : null}
        {filePopupVisible && (
          <FilePopup fileSrc={fileSrc} showFilePopup={showFilePopup} />
        )}

        {content.map((data, i) => {
          if (data.type === "text") {
            return (
              <p
                key={data.id}
                style={{
                  visibility: isVisible ? "visible" : "hidden",
                  animationDuration: "4.5s",
                }}
                className={
                  isIntersected || !isHome
                    ? "about-text animate__animated animate__fadeIn animate__slower"
                    : "about-text"
                }
              >
                {data.content}
              </p>
            );
          } else {
            return null;
          }
        })}
        {!isHome && (
          <div className="image-card-grid">
            {imageCardsData.map((data) => {
              return (
                <ImageCard
                  key={data.id}
                  imgUrl={data?.img}
                  title={data.title}
                  content={data.text}
                  linkTitle={data.linkText}
                  link={
                    data.title === "Bespoke Portfolio Service (BPS)"
                      ? latestBespoke
                      : latestManaged
                  }
                  customAction={handleShowPdf}
                />
              );
            })}
          </div>
        )}

        {isHome && (
          <div>
            <HomeLink
              isIntersected={isIntersected}
              isVisible={isVisible}
              pathname="/investment-management"
            />
          </div>
        )}
        {isHome && !isMobile && (
          <div className="arrow-container-page">
            <HomeArrow
              slideUp={slideUp}
              index={index}
              color="blue"
              previous="hero"
              type="UP"
            />

            <HomePagination page={index} maxPages={maxPages} />
            <HomeArrow
              slideDown={slideDown}
              index={index}
              color="blue"
              next="financial-planning"
              type="DOWN"
            />
          </div>
        )}
      </article>
    </section>
  );
}

// {!isMobile ? (
//   <h1 className="page-header">Investment Management</h1>
// ) : null}
