import React from "react";
import { Link } from "react-router-dom";
import "./ClientLogin_desk.css";
import "./ClientLogin_mob.css";

export default function ClientLogin() {
  return (
    <div className="client-login-container">
      <div>
        <Link to="/client-login" className="client-login-link">
          Client Login
        </Link>
      </div>
    </div>
  );
}
