import { uuid } from "uuidv4";
import noProfile from "../media/profile2.png";

let teamData = [
  {
    id: uuid(),
    name: "Brendan Coburn",
    position: "Director & Chartered financial planner",
    imgUrl: "https://media.graphassets.com/lohlZbvcTHyBlaE3YtR3",
    bio: "Brendan has worked in financial services for his entire thirty-year career and during this time has worked in several different sectors. He began his career in retail banking before moving to PWC and then leaving to set up an insurance brokerage business. This business was sold in 2009 at which point Brendan moved into financial advice where he has worked for the last decade.",
    email: "brendanc@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/brendan-coburn-8696a446/",
  },
  {
    id: uuid(),
    name: "Alasdair McWilliams",
    position: "Director & financial planner",
    imgUrl: "https://media.graphassets.com/rWB3cikjSpSwQiBPiPEa",
    bio: "Alasdair has a degree in Physics from King’s College London and a Masters in Applied Mathematics from Imperial College London. After his Masters he did research in the Institute of Theoretical Physics at the Vienna University of Technology. He has been a financial planner since 2017 and is one of the founders of Throgmorton Capital Management.",
    email: "alasdairm@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/alasdair-mcwilliams-12b01579/",
  },
  {
    id: uuid(),
    name: "Peter Botham",
    position: "Investment Director",
    imgUrl: "https://media.graphassets.com/hbemYaER5Kk6iAcM2Kge",
    bio: "Peter is the lead investment manager for the firm’s Managed Portfolio Service. He has over 30 years investment experience, having run unit trusts and portfolios for pension funds, charities and private clients. He worked for a decade at Tilney Investment Management before leaving to start his own firm, which was eventually acquired by Brown Shipley. After the acquisition Peter stayed with Brown Shipley for eight years to serve as the firm’s Chief Investment Officer.",
    email: "peter.botham@throgmortoncm.co.uk",
    linkedIn: "",
  },
  {
    id: uuid(),
    name: "Matthew Singleton",
    position: "Investment Director",
    imgUrl: "https://media.graphassets.com/mS45Bwy0TAyCNWVYzIex",
    bio: "Matthew began his career at the stockbrokers James Brearley & Sons. After five years here he moved to work as an investment manager at WH Ireland where he looked after assets for high earning professionals and high net worth individuals. After five years at WH Ireland Matthew moved to Daintree Wealth Management where he served as Chief Investment Officer for again just over five years. He joined Throgmorton as an Investment Director in January 2021.",
    email: "matthews@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/matthew-singleton-14874725/",
  },
  {
    id: uuid(),
    name: "Peter Ward",
    position: "Financial Planner",
    imgUrl: "https://media.graphassets.com/PiswETS86FNUibkoQAO8",
    bio: "Peter worked for NatWest's Private Bank for 18 years before moving to a financial services firm in Newark where he spent several more years. In 2018 Peter joined Chattertons Wealth Management as their financial consultant before moving to Throgmorton in January 2020 as an independent financial adviser. Peter has over 30 years of experience in financial services and has always specialised in providing advice in relation to investments, pensions and protection.",
    email: "peter.ward@throgmortoncm.co.uk",
    linkedIn:
      "https://www.linkedin.com/in/peter-ward-cefa-dipfa-celtci-b72bb253/",
  },
  {
    id: uuid(),
    name: "Lisa Welby",
    position: "Office Manager",
    imgUrl: "https://media.graphassets.com/Vh9zwd9oQjqhhfmUQnbv",
    bio: "Lisa is the Office Manager at Throgmorton. She has over 25 years of administrative experience within financial advice firms and is responsible for designing and overseeing the implementation of the policies and processes which enable the firm to run efficiently and deliver an outstanding service to its clients.",
    email: " lisa.black@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/lisa-welby-21994822/",
  },
  {
    id: uuid(),
    name: "Jacob Wolf",
    position: "Business Consultant",
    imgUrl: noProfile,
    bio: "Jacob qualified as a Chartered Accountant in 1979, as a Chartered Tax Adviser in 1980 and then as a Certified Financial Planner in 1999. As a result of a merger with his then firm, Jacob joined Crowe Clark Whitehill in 2001 where he became the partner in charge of Personal Financial Planning at the Manchester office. He retired from Crowe in 2016 at which point he joined a financial advice firm before moving to Throgmorton in January 2020 as a business consultant.",
    email: "",
    linkedIn: "https://www.linkedin.com/in/jacob-wolf-10b70457/",
  },
  {
    id: uuid(),
    name: "Lorraine Shaw",
    position: "Administrator",
    imgUrl: noProfile,
    bio: "",
    email: "Lorraine.Shaw@throgmortoncm.co.uk",
    linkedIn: "",
  },
  {
    id: uuid(),
    name: "Helena Garthwaite",
    position: "Administrator",
    imgUrl: noProfile,
    bio: "Helena started her working life in the engineering industry and worked for 5 years in an office/factory starting as a filing clerk and moving onto processing customer orders. Following this, she then worked within the printing industry for 24 years working in Customer Services, Admin. Pick and Pack and Purchasing. And has been working as an administrator within the financial services industry for the last 14 years before joining Throgmorton in August 2021.",
    email: "Helena.Garthwaite@throgmortoncm.co.uk",
    linkedIn: "",
  },
  {
    id: uuid(),
    name: "Lisa Davies",
    position: "Financial Planner",
    imgUrl: "https://media.graphassets.com/wYUrxDcQRxut1wgAwyPo",
    bio: "Lisa has worked in Financial Services for over 25 years. Her early career was spent at Barclays Wealth Management in Jersey, Channel Islands, dealing with Trust and Estate Planning for high net worth clients. She moved into Property Sales and Management in 2001, managing an estate agency in Spain until 2007. She then returned to the UK to resume her career in Financial Services, initially returning to Barclays Wealth in the UK as a Financial Planner, prior to becoming an Independent Financial Planner. Lisa is especially passionate about helping people with their finances during difficult times such as bereavement and divorce. Lisa is an expert listener and understands that life can often throw you a curve ball now and then, she looks for the positives in any situation and will steer you in the right financial direction. She enjoys building long lasting relationships with clients and helping them plan for their various life stages, and will do her best to ensure you achieve your financial objectives and life goals.",
    email: "lisa.davies@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/lisa-davies-dipfa-awpetr-34742176/",
  },
  {
    id: uuid(),
    name: "Richard Wadsworth",
    position: "Financial Planner",
    imgUrl: "https://media.graphassets.com/JeIMolpNTcW6M6BwSgfQ",
    bio: "Richard has worked as a financial adviser for his entire 27 year career and during that time has worked within several different companies. He began his career at the Prudential before becoming an Independent Financial Adviser at the Co-Operative Bank. After this, in 2010, he set up his own financial advisory business which he ran for several years and eventually sold. Since then, Richard has continued to work as a financial planner and joined Throgmorton in May 2022 bringing with him a great deal of experience in building long-term client relationships.",
    email: "richard.wadsworth@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/richard-wadsworth-25395a16a/",
  },
  {
    id: uuid(),
    name: "Philip Grieveson",
    position: "Administrator",
    imgUrl: "https://media.graphassets.com/KgDYaITfGeSL23nT51AA",
    bio: "Phil graduated from UCLAN in 2008 with a degree in Philosophy. Since then he has held various roles, the most recent being at a large independent financial adviser firm where he has worked for the last 5 years as an administrator. He spent time rotating between departments, working in the valuations department, the discretionary fund management team as well as with the personal injury trusts. ",
    email: "philip.grieveson@throgmortoncm.co.uk",
    linkedIn: "",
  },
  {
    id: uuid(),
    name: "Connor Mills",
    position: "Trainee Financial Planner",
    imgUrl: "https://media.graphassets.com/BA9SAUVQTIS8VhobVM2g",
    bio: "Connor began his working life at an engineering firm before moving into financial services in early 2020 as an administrator at Lancashire Financial Services. He spent two years there before joining Throgmorton in March 2022.",
    email: "connor.mills@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/connor-mills-39705b222/",
  },
  {
    id: uuid(),
    name: "Aylin Bayraktar",
    position: "Trainee Financial Planner",
    imgUrl: "https://media.graphassets.com/X0x3oKleTFyKx1duAope",
    bio: "Aylin joined Throgmorton in July 2022 as a Trainee Financial Planner after completing a degree in Banking and Finance at Manchester Metropolitan University. While studying for her degree Aylin worked at Handelsbanken as Account Manager Support which saw her supporting Handelsbanken's high net worth clients with their banking needs. Aylin is bilingual having studied in Turkey and lived in the USA.",
    email: "aylin.bayraktar@throgmortoncm.co.uk",
    linkedIn: "https://www.linkedin.com/in/aylin-bayraktar/",
  },
];

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const personA = a.name.toUpperCase();
  const personB = b.name.toUpperCase();

  let comparison = 0;
  if (personA > personB) {
    comparison = 1;
  } else if (personA < personB) {
    comparison = -1;
  }
  return comparison;
}

teamData = teamData.sort(compare);

export { teamData };
