import React, { useEffect } from "react";
import { locationData } from "../data/locationData";
import "./Privacy_desk.css";
import "./Privacy_mob.css";
export default function Privacy() {
  useEffect(() => {
    document.title = "Privacy | Throgmorton Capital Management";
  });

  return (
    <section className="privacy-section">
      <h1 className="privacy-header">Privacy Policy</h1>
      <hr />
      <h2>Introduction</h2>
      <p>
        This notice provides you with information concerning our collection and
        use of personal data. If you have any queries regarding this statement,
        please do not hesitate to contact us.
      </p>
      <h2>Legal Basis</h2>
      <p>
        We collect, control and process your personal information because this
        is necessary to provide you with information, answer any queries you may
        have and for the provision of our services to you. Contractual necessity
        is therefore the lawful basis for collecting, controlling and processing
        your personal details and those of your employees and service providers
        other than sensitive personal data for which we require individual
        consent. We do not normally request or process any sensitive personal
        data.
      </p>
      <h3>Sensitive personal data:</h3>
      <ul>
        <li>Racial or ethnic origin</li>
        <li>Political opinion</li>
        <li>Religious or philosophical beliefs</li>
        <li>Trade union membership</li>
        <li>Genetic data</li>
        <li>Biometric data</li>
        <li>Data concerning health</li>
        <li>Data concerning sex life or sexual orientation</li>
      </ul>
      <p>
        We would discuss with you the need for the provision of sensitive data
        before you provide this information. The subsequent provision of
        sensitive personal data will be taken as consent to this processing.
      </p>
      <p>
        It is entirely up to you as to whether or not you choose to provide us
        with any personal information. If you choose not to, we simply may not
        be able to assist you.
      </p>
      <p>
        We do not use your personal data for marketing purposes nor will we
        share your data with any other organisation for marketing purposes.
      </p>
      <h2>What data we collect & what do we do with it</h2>
      <p>
        We collect and use information about you, which will include personal
        data in order for us to advise you and make arrangements for you. In
        order to communicate with you about our services we will require your
        contact details including personal email addresses. Your name and email
        and information relating to the work we undertake for you will be
        processed and stored.
      </p>
      <h2>Sharing your data</h2>
      <p>
        In order to fulfill our obligations under any contract for services we
        will need to share your data with third party Data Controllers and Data
        Processors.
      </p>
      <h3>Data Controllers</h3>
      <p>This will include:</p>
      <ul>
        <li>
          Product &amp; service providers in relation to investments, plans and
          pensions.
        </li>
        <li>Our compliance monitoring services.</li>
        <li>The Financial Conduct Authority.</li>
        <li>The Financial Ombudsman Service.</li>
        <li>Any other regulators where so required.</li>
        <li>Para-planning (investment research) services.</li>
      </ul>
      <p>
        We also use external Data Processors that will hold information for the
        following purposes:
      </p>
      <ul>
        <li>Research</li>
        <li>Secure file sharing</li>
        <li>Data backup</li>
        <li>Communications</li>
        <li>Accounts</li>
        <li>Forecasting</li>
        <li>Record keeping</li>
        <li>Regulatory compliance</li>
      </ul>
      <p>
        All external processors are only permitted to process your data in line
        with our instructions.
      </p>
      <h3>Data processors privacy</h3>
      <p>
        Below is a list of the processors used and the types of information
        processed. Please note that the Privacy & Security policies of the
        processors are aimed at the controllers who use them and may be updated.
      </p>
      <ul>
        <li>
          <strong>Intelligent Office</strong>
          <br />
          We use Intelligent Office as our administration system. Your personal
          data is added to the system and stored when you become a client.
        </li>
        <li>
          <a href="https://www.dropbox.com/privacy#Dropbox%20Privacy">
            <strong>Dropbox</strong>
          </a>
          <br />
          We use Dropbox to securely share files and documents that contain or
          may contain personal data pertinent to the work we undertake for
          clients and for our staff.
        </li>
        <li>
          <a href="https://www.genovo.co.uk/privacy-policy/#Genovo%20privacy%20policy">
            <strong>PPOL</strong>
          </a>
          <br />
          We use Paraplanning Online’s software to generate reports and your
          personal data pertaining to our advice is entered during this process
          and stored.
        </li>
        <li>
          <a href="https://www.dynamicplanner.com/home/admin/privacy/">
            <strong>Distribution Technology</strong>
          </a>
          <br />
          We use Distribution Technology for research and enter minimal personal
          information – name only – to identify which client the research
          relates to.
        </li>
      </ul>
      <p>
        In all cases we seek to minimise the amount of personal data shared and
        in some cases anonomise this. It is essential, however, that we can
        identify you quickly and easily so information shared may be
        identifiable as your personal data.
      </p>
      <p>
        We will not share your personal information in any other circumstances
        than those necessary for the provision of service to you or where we are
        required to do so by law.
      </p>
      <h2>Where we store your Personal Data</h2>
      <p>
        All the data that we collect is stored on our encrypted server or
        encrypted back-up drives back up drives or with our listed processors
        under contract.
      </p>
      <h2>Marketing</h2>
      <p>
        The information we collect about you is used solely for the purposes for
        which it was provided. Where we have collected information form you for
        marketing purposes we will inform you and specifically gain your
        consent.
      </p>
      <h2>Your rights</h2>
      <p>
        Under data protection law you have the right to ask us for a copy of the
        information we hold about you, and to have any inaccuracies corrected or
        removed. You may also ask us to delete or cease processing all personal
        data held by us or any processor with which we have shared your data. We
        may not always be able to comply with a request for deletion, but you
        can ask us to cease processing your data.
      </p>
      <p>
        In addition you have the right to be informed about the data we collect,
        where is located and with whom it is shared and the processing we
        undertake and to question any automated decision making processes
      </p>
      <p>
        To do this, or if you require more information please either contact us
        in writing, by telephone or email.
      </p>
      <h2>Complaints</h2>
      <p>
        We are registered as a Data Controller in the UK with the Information
        Commissioner’s Office. Click{" "}
        <a href="https://ico.org.uk/for-the-public/#ICO">here</a> for more
        information.
      </p>
      <h2>Contact</h2>
      <p>For any queries about data protection please contact:</p>
      <address>
        Brendan Coburn
        <br />
        Throgmorton Capital Management
        <br />
        {locationData[0].city}
        <br /> {locationData[0].addrLine1}
        <br /> {locationData[0].addrLine2}
        <br /> {locationData[0].addrLine3}
        <br /> {locationData[0].addrLine4}
        <br /> {locationData[0].telephone}
      </address>
      <h2>IP Addresses and Cookies</h2>
      <p>Our website uses cookies purely to enhance your experience.</p>
      <p>
        (A cookie is a piece of information that is stored on your computer’s
        hard drive which records how you have used a website. This means that
        when you go back to that website, it can give you tailored options based
        on the information it has stored about your last visit.)
      </p>
      <h3>Vital cookies</h3>
      <p>
        Ones that are essential to the operation of the website. These cookies
        enable a site to remember text entered in a page within the same session
        or to remember whether a user is logged in or not, the site cannot
        function without them.
      </p>
      <h3>Performance cookies</h3>
      <p>
        Performance cookies, which collect information about website usage to
        enhance the web experience. Examples of these include website analytics
        (i.e. Google Analytics) and ad-response rates (where data is collected
        exclusively for calculating click-through rates). They don’t collect
        information that can identify a particular visitor. These cookies are
        usually persistent and have fairly long expiry rates.
      </p>
      <h3>Functionality cookies</h3>
      <p>
        These remember the choices that users make within a web page, such as
        usernames, language or region to provide an enhanced, more personalised
        web experience. They cannot track your activity on other websites and
        are anonymous.
      </p>
      <h3>Advertising cookies</h3>
      <p>
        Targeting or advertising cookies collect information about your browsing
        habits to tailor third-party advertising to meet your interests. They
        are usually placed by advertisers with website operators’ permission. We
        do not use this type of cookie.
      </p>
      <p>
        Any changes we may make to our privacy policy in the future will be
        posted on our websites where appropriate or may be notified to you by
        post or e-mail.
      </p>
    </section>
  );
}
