import React from "react";

export default function Location({
  isVisible,
  isIntersected,
  isHome,
  city,
  addrLine1,
  addrLine2,
  addrLine3,
  addrLine4,
  linkedin,
  telephone,
  showEmailandTel,
  hideLinkedIn,
}) {
  return (
    <>
      <div
        style={{
          visibility: isVisible ? "visible" : "hidden",
          animationDuration: "4.5s",
        }}
        className={
          isIntersected || !isHome
            ? " animate__animated animate__fadeIn animate__slower location-container"
            : "location-container"
        }
      >
        <address className="contact-address">
          <p className="address-text">{city}</p>
          <p className="address-text">{addrLine1}</p>
          <p className="address-text">{addrLine2}</p>
          <p className="address-text">{addrLine3}</p>
          <p className="address-text">{addrLine4}</p>
        </address>
        {showEmailandTel && (
          <div
            style={{
              visibility: isVisible ? "visible" : "hidden",
              animationDuration: "4.5s",
            }}
            className={
              isIntersected || !isHome
                ? " animate__animated animate__fadeIn animate__slower"
                : ""
            }
          >
            <label className="contact-form-label" htmlFor="">
              Telephone
            </label>
            <a className="contact-link" href={`tel:${telephone}`}>
              {telephone}
            </a>
          </div>
        )}
        {showEmailandTel && (
          <>
            <div
              style={{
                visibility: isVisible ? "visible" : "hidden",
                animationDuration: "4.5s",
              }}
              className={
                isIntersected || !isHome
                  ? " animate__animated animate__fadeIn animate__slower"
                  : ""
              }
            >
              <label className="contact-form-label" htmlFor="">
                Email
              </label>
              <a
                className="contact-link"
                href="mailto:enquiries@throgmortoncm.co.uk"
              >
                enquiries@throgmortoncm.co.uk
              </a>
            </div>

            <div
              style={{
                visibility: isVisible && !hideLinkedIn ? "visible" : "hidden",
                animationDuration: "4.5s",
              }}
              className={
                isIntersected || !isHome
                  ? "animate__animated animate__fadeIn animate__slower"
                  : ""
              }
            >
              <a
                className="throg-footer-link linkedin-link linkedin-link-contact"
                href="https://linkedin.com/company/throgmorton-capital-management"
                target="_blank"
              >
                <img className="menu-linkedin-img" src={linkedin} alt="" />
              </a>
            </div>
          </>
        )}
      </div>
    </>
  );
}
