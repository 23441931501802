export const locationData = [
  {
    city: "3 The Stables",
    addrLine1: "Wilmslow Road",
    addrLine2: "East Didsbury",
    addrLine3: "Manchester",
    addrLine4: "M20 5PG",
    telephone: "0161 249 6171",
    hideLinkedIn: true,
  },
];
