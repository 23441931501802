import { uuid } from "uuidv4";

export const clientLoginData = {
  imageCards: [
    {
      id: uuid(),
      title: "THE PERSONAL FINANCE PORTAL",
      text: [
        "The Personal Finance Portal (PFP) is a service available to all Throgmorton clients. Through PFP you can access to view all your finances in one place, 24/7, on any mobile or web device. PFP enables you to view your fund information and financial portfolio in an instant. So whether you're looking for an up to date valuation of your portfolio, want to assess how you're progressing against your goals or simply wish to get in touch, PFP has it covered.",
      ],
      img:
        "https://storage.googleapis.com/throgmortoncm-files/main-images/personal-login-img-min.jpg",
      linkText: "LOGIN TO THE PERSONAL FINANCE PORTAL",
      link: "https://throgmortoncm.mypfp.co.uk/",
    },
    {
      id: uuid(),
      title: "BESPOKE PORTFOLIO SERVICE",
      text: [
        "Our chosen platform Raymond James provides you with instant online access to your investment portfolio, allowing you to access information and check valuations, transactions and statements. This is of course in addition to regular personal updates and commentary that your investment manager will provide you with.",
      ],
      img:
        "https://storage.googleapis.com/throgmortoncm-files/main-images/bps-login-img-min.jpg",
      linkText: "LOGIN TO RAYMOND JAMES CLIENT ACCESS",
      link: "https://clientaccess.rjis.co.uk/signin",
    },
  ],
};
