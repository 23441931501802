import React, { useEffect, useContext, useState } from "react";
import "./Team_desk.css";
import "./Team_mob.css";
import { MobileContext } from "../App";

import Modal from "react-modal";
import linkedin from "../media/linkedin2.svg";
import { teamData } from "../data/teamData";

Modal.setAppElement("#modal");

export default function Team() {
  let isMobile = useContext(MobileContext);

  const [selectedImage, setSelectedImage] = useState(0);
  const [team, setTeam] = useState(teamData);
  const [animate, shouldAnimate] = useState(true);

  useEffect(() => {
    document.title = "Our Team | Throgmorton Capital Management";
  }, []);

  useEffect(() => {
    fetch("https://europe-west2-throgmortoncapital.cloudfunctions.net/getTeam")
      .then((res) => res.json())
      .then((data) => {
        setTeam(data.teamMembers);
      });
  }, []);

  return (
    <section className="page-section" id="our-team">
      <div className="page-col-1 team-col-1">
        {isMobile ? <h1 className="page-header">Our team</h1> : null}
        <img
          className={
            animate
              ? "page-img team-img animate__animated animate__fadeIn animate__slow"
              : "page-img team-img"
          }
          src={team[selectedImage]?.image?.url}
          alt=""
        />
      </div>

      <div className="page-col-2 team-col-2">
        {!isMobile ? <h1 className="page-header">Our Team</h1> : null}

        <div className="team-main-cont">
          <div className="team-user-select-cont">
            {team.map((t, i) => {
              return (
                <div key={`${t.fullName}-${i}`}>
                  <button
                    className={
                      selectedImage === i
                        ? "active-team team-select-btn"
                        : "team-select-btn"
                    }
                    onClick={() => {
                      shouldAnimate(true);
                      setSelectedImage(i);
                    }}
                  >
                    {t.fullName}
                  </button>
                </div>
              );
            })}
          </div>

          <article
            onAnimationEnd={() => {
              shouldAnimate(false);
            }}
            className={
              animate
                ? "our-team-article animate__animated animate__fadeInUp"
                : "our-team-article"
            }
          >
            <h1 className="team-card-name">{team[selectedImage].fullName}</h1>
            <h2 className="team-card-role">{team[selectedImage].position}</h2>
            <p className="team-bio">{team[selectedImage].bio}</p>

            <div className="team-links-cont">
              {team[selectedImage].linkedin && (
                <a
                  target="_blank"
                  className="bio-link"
                  href={team[selectedImage].linkedin}
                >
                  <img src={linkedin} alt="" />
                </a>
              )}
              {team[selectedImage].emailAddress && (
                <a
                  className="bio-link team-email-link"
                  href={`mailto:${team[selectedImage].emailAddress}`}
                >
                  {`Email ${team[selectedImage].fullName?.split(" ")[0]}`}
                </a>
              )}
            </div>
          </article>
        </div>
      </div>
    </section>
  );
}
