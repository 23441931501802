import { uuid } from "uuidv4";

const latestBespokeBrochure =
  "ttps://europe-west2-throgmortoncapital.cloudfunctions.net/getLatestBespokeBrochure";
const latestFPBrochure =
  "https://europe-west2-throgmortoncapital.cloudfunctions.net/getLatestFPBrochure";
const latestManagedBrochure =
  "https://europe-west2-throgmortoncapital.cloudfunctions.net/getLatestManagedBrochure";

export const financialPlanningContent = {
  main: [
    {
      id: uuid(),
      type: "text",
      content:
        "We work with individuals to create and maintain a financial strategy. Our clients are a wonderful group of extremely diverse people, diverse in every sense: they encompass a wide range of ages and backgrounds, come from a variety of professions and have differing levels of investment experience. Our service is entirely personalised to each client, and we believe that we can add significant value for anyone.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Our aim is that our advice makes or saves you money and gives you both peace of mind and financial confidence.",
    },
  ],
  truncated: [
    {
      id: uuid(),
      type: "text",
      content:
        "We work with individuals to create and maintain a financial strategy. Our clients are a wonderful group of extremely diverse people, diverse in every sense: they encompass a wide range of ages and backgrounds, come from a variety of professions and have differing levels of investment experience. Our service is entirely personalised to each client, and we believe that we can add significant value for anyone.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Our aim is that our advice makes or saves you money and gives you both peace of mind and financial confidence.",
    },
  ],
  imageCards: [
    {
      id: uuid(),
      title: "discover more about our financial planning services",
      text: [
        "To give our prospective clients a detailed guide of the services we provide and whether these can assist you in your financial journey to financial security giving you peace of mind.",
      ],
      img: "https://storage.googleapis.com/throgmortoncm-files/main-images/fp-1-min.jpg",
      linkText: "download our financial planning brochure",
      link: "https://storage.googleapis.com/throgmortoncm-files/brochures/Financial%20Planning%20Brochure.pdf",
    },
  ],
};
