import React, { useRef, useContext } from "react";

import Hero from "../hero/Hero";
import { useState } from "react";
import { useEffect } from "react";
import Contact from "../contact/Contact";
import About from "../about/About";
import { whatWeDoContent } from "../data/whatWeDoContent";
import FinancialPlanning from "../services/FinancialPlanning";
import InvestmentManagement from "../services/InvestmentManagement";
import { financialPlanningContent } from "../data/financialPlanningContent";
import { investmentManagementContent } from "../data/investmentManagementContent";
import { MobileContext } from "../App";
import CulturePage from "../culture/CulturePage";
import { visionContent } from "../data/visionContent";
import StoryPage from "../our-story/StoryPage";
import { ourStoryContent } from "../data/ourStoryData";
import ScrollAnimation from "react-animate-on-scroll";

export default function Home({ shouldBeBlueLogo, setIsHome }) {
  const [nextIndex, setNextIndex] = useState(0);
  const [top, setTop] = useState(0);

  const container = useRef();
  const MAX_INDEX = 6;
  let isMobile = useContext(MobileContext);

  useEffect(() => {
    document.title = "Home | Throgmorton Capital Management";

    window.scrollX = 0;

    setIsHome(true);

    window.addEventListener(
      "keyup",
      (e) => {
        if (e.key === "ArrowDown") {
          if (nextIndex !== MAX_INDEX) {
            setTimeout(() => {
              slideDown(nextIndex);
            }, 200);
          }
        } else if (e.key === "ArrowUp") {
          if (nextIndex !== 0) {
            setTimeout(() => {
              slideUp(nextIndex);
            }, 200);
          }
        }
      },
      {
        capture: true,
        once: true,
      },
    );
  }, [nextIndex]);

  const slideDown = (index) => {
    setTimeout(() => {
      setTop(0 - window.innerHeight * (index + 1));
      setNextIndex(index + 1);
      if (index + 1 === MAX_INDEX - 1) {
        shouldBeBlueLogo(true);
      } else {
        shouldBeBlueLogo(false);
      }
    }, 600);
  };

  const slideUp = (index) => {
    setTimeout(() => {
      setTop(0 - window.innerHeight * (index - 1));
      setNextIndex(index - 1);
      if (index - 1 === MAX_INDEX - 1) {
        shouldBeBlueLogo(true);
      } else {
        shouldBeBlueLogo(false);
      }
    }, 600);
  };

  if (!isMobile) {
    return (
      <div
        style={{
          position: "absolute",
          top: `${top}px`,

          transition: "all 1s linear",
          overflow: "scroll",
          width: "100vw",
        }}
        ref={container}
        onScroll={(e) => {}}
        onWheel={(e) => {
          let index = Number(e.target.dataset.index);

          if (e.deltaY >= 1 && index >= 0) {
            if (index === MAX_INDEX) {
              return;
            }

            setTimeout(() => {
              slideDown(index);
            }, 0);
          }
          if (e.deltaY < 0 && index) {
            setTimeout(() => {
              slideUp(index);
            }, 0);
          }
        }}
      >
        <Hero
          slideDown={slideDown}
          index={0}
          setIsHome={() => {}}
          isHome={true}
          maxPages={MAX_INDEX}
        />

        <StoryPage
          content={ourStoryContent.truncated}
          slideDown={slideDown}
          slideUp={slideUp}
          isHome={true}
          isIntersected={nextIndex === 1}
          index={1}
          maxPages={MAX_INDEX}
        />
        <CulturePage
          content={visionContent.truncated}
          slideDown={slideDown}
          slideUp={slideUp}
          isHome={true}
          isIntersected={nextIndex === 2}
          index={2}
          maxPages={MAX_INDEX}
        />
        <About
          slideDown={slideDown}
          slideUp={slideUp}
          isHome={true}
          isIntersected={nextIndex === 3}
          index={3}
          content={whatWeDoContent.truncated}
          maxPages={MAX_INDEX}
        />
        <FinancialPlanning
          slideDown={slideDown}
          slideUp={slideUp}
          isHome={true}
          isIntersected={nextIndex === 4}
          index={4}
          content={financialPlanningContent.truncated}
          imageCardsData={financialPlanningContent.imageCards}
          maxPages={MAX_INDEX}
        />

        <InvestmentManagement
          slideDown={slideDown}
          slideUp={slideUp}
          isHome={true}
          isIntersected={nextIndex === 5}
          index={5}
          content={investmentManagementContent.truncated}
          maxPages={MAX_INDEX}
        />
        <Contact
          isIntersected={nextIndex === 6}
          slideUp={slideUp}
          isHome={true}
          index={6}
          maxPages={MAX_INDEX}
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          transition: "all 1s linear",
          overflow: "scroll",
          width: "100vw",
        }}
        ref={container}
      >
        <Hero
          slideDown={slideDown}
          index={0}
          setIsHome={() => {}}
          isHome={true}
          maxPages={MAX_INDEX}
        />
        <ScrollAnimation
          duration={2}
          animateIn="custom-animation"
          animateOnce={true}
        >
          <StoryPage
            content={ourStoryContent.truncated}
            slideDown={slideDown}
            slideUp={slideUp}
            isHome={true}
            isIntersected={nextIndex === 1}
            index={1}
            maxPages={MAX_INDEX}
          />
        </ScrollAnimation>
        <ScrollAnimation
          duration={2}
          animateIn="custom-animation"
          animateOnce={true}
        >
          <CulturePage
            content={visionContent.truncated}
            slideDown={slideDown}
            slideUp={slideUp}
            isHome={true}
            isIntersected={nextIndex === 2}
            index={2}
            maxPages={MAX_INDEX}
          />
        </ScrollAnimation>
        <ScrollAnimation
          duration={2}
          animateIn="custom-animation"
          animateOnce={true}
        >
          <About
            slideDown={slideDown}
            slideUp={slideUp}
            isHome={true}
            isIntersected={nextIndex === 3}
            index={3}
            content={whatWeDoContent.truncated}
            maxPages={MAX_INDEX}
          />
        </ScrollAnimation>
        <ScrollAnimation
          duration={2}
          animateIn="custom-animation"
          animateOnce={true}
        >
          <FinancialPlanning
            slideDown={slideDown}
            slideUp={slideUp}
            isHome={true}
            isIntersected={nextIndex === 4}
            index={4}
            content={financialPlanningContent.truncated}
            imageCardsData={financialPlanningContent.imageCards}
            maxPages={MAX_INDEX}
          />
        </ScrollAnimation>
        <ScrollAnimation
          duration={2}
          animateIn="custom-animation"
          animateOnce={true}
        >
          <InvestmentManagement
            slideDown={slideDown}
            slideUp={slideUp}
            isHome={true}
            isIntersected={nextIndex === 5}
            index={5}
            content={investmentManagementContent.truncated}
            maxPages={MAX_INDEX}
          />
        </ScrollAnimation>
        <ScrollAnimation
          duration={2}
          animateIn="custom-animation"
          animateOnce={true}
        >
          <Contact
            isIntersected={nextIndex === 6}
            slideUp={slideUp}
            isHome={true}
            index={6}
            maxPages={MAX_INDEX}
          />
        </ScrollAnimation>
      </div>
    );
  }
}
