import React, { useEffect, useContext, useState } from "react";
// import "./Services_desk.css";
// import "./Services_mob.css";

import { MobileContext } from "../App";
import PageHeader from "../wrappers/PageHeader";
import { clientLoginImg } from "../data/imageData";

import ImageCard from "../components/ImageCard";

import { clientLoginData } from "../data/clientLoginData";

export default function ClientLoginPage({
  content,
  isHome,
  isIntersected,
  index,
  slideDown,
  slideUp,
}) {
  let isMobile = useContext(MobileContext);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isHome)
      document.title = "Client Login | Throgmorton Capital Management";

    // setIsVisible(false);

    if (isIntersected === false && !isMobile) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    if (isMobile) {
      setIsVisible(true);
    }
  }, [isHome, isIntersected]);

  return (
    <section
      id="investment-management"
      className={
        isHome
          ? "page-section services-section alternate-page-section"
          : "page-section services-section"
      }
    >
      <div className="page-col-1">
        <img
          data-index={index}
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
          className={
            isIntersected || !isHome
              ? "page-img animate__animated animate__fadeIn animate__slower"
              : "page-img"
          }
          src={clientLoginImg}
          alt=""
        />
        {isMobile ? <PageHeader title="Client Login" /> : null}
      </div>
      <article data-index={index} className="page-col-2">
        {!isMobile ? (
          <h1
            onAnimationStart={() => {
              setIsVisible(true);
            }}
            style={{
              visibility: isVisible ? "visible" : "hidden",
            }}
            className={
              isIntersected || !isHome
                ? "page-header animate__animated animate__fadeInDown animate__slow"
                : "page-header"
            }
          >
            Client Login
          </h1>
        ) : null}

        {!isHome && (
          <div className="image-card-grid">
            {clientLoginData.imageCards.map((c, i) => {
              return (
                <ImageCard
                  key={c.id}
                  imgUrl={c.img}
                  title={c.title}
                  content={c.text}
                  linkTitle={c.linkText}
                  link={c.link}
                  customAction={(...args) => {}}
                />
              );
            })}
          </div>
        )}
      </article>
    </section>
  );
}

// {!isMobile ? (
//   <h1 className="page-header">Investment Management</h1>
// ) : null}
