import media_img from "../media/media-img.jpg";

export const heroImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/home-img-min.jpg";
export const whatWeDoImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/what-we-do-min.jpg";
export const storyImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/story-img-min.jpg";
export const visionImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/vision-img-min.jpg";
export const clientLoginImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/client-login-img-min.jpg";

export const financialPlanningImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/financial-planning-v2-min.jpg";
export const investmentManagementImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/investment-management-min.jpg";
export const careersImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/careers-min.jpg";
export const contactImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/contact-v2-min.jpg";
export const downloadsImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/downloads-min.jpg";
export const mpsImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/mps-brochure-img-min-v2.jpg";
export const bpsImg =
  "https://storage.googleapis.com/throgmortoncm-files/main-images/bps-login-img-min.jpg";
export const mediaImg = media_img;
