import React from "react";
import { useEffect } from "react";
import "./BestExecution_desk.css";
import "./BestExecution_mob.css";

export default function BestExecution() {
  useEffect(() => {
    document.title = "Best Execution Policy | Throgmorton Capital Management";
  });

  return (
    <section className="best-execution-section">
      <h1 className="best-execution-header">Best Execution Policy</h1>
      <hr />
      <p>
        <strong>Overview</strong>
      </p>
      <p>
        It is in the interests of our clients and our firm that we obtain the
        best possible result when placing orders with other firms e.g. third
        party brokers and fund managers for the execution of client orders or
        when transmitting orders on behalf of clients. We are required to take
        all reasonable steps to provide best execution when carrying out such
        transactions and, on your request, to provide you with a copy of the
        policy that we have adopted to achieve that objective.
      </p>
      <p>
        This best execution policy applies to orders in financial instruments
        such as funds and other securities. You should read this policy in
        conjunction with your discretionary management agreement.
      </p>
      <p>
        <strong>Execution Factors</strong>
      </p>
      <p>
        When placing orders with other firms for the execution of orders or when
        transmitting orders, we will make every effort to ensure the best
        possible result for our clients taking into account the following
        factors:
      </p>
      <ul>
        <li>Price</li>
        <li>Cost</li>
        <li>Speed</li>
        <li>Likelihood of execution and settlement</li>
        <li>Size</li>
        <li>Nature of the order</li>
        <li>Any other relevant consideration</li>
      </ul>
      <p>
        For retail clients, the price and cost of execution of the order will
        normally be the most important aspect in obtaining the best possible
        result. We will therefore assume this is the most important outcome for
        your transaction unless you tell us otherwise.
      </p>
      <p>
        <strong>Executing your order</strong>
      </p>
      <p>In arranging for the execution of your order:</p>
      <ul>
        <li>
          We may use a third party broker or platform to execute your order;
        </li>
        <li>
          We may trade as an agent (where our scope of regulatory permissions
          allow us to do so);
        </li>
      </ul>
      <p>
        <strong>Execution venues</strong>
      </p>
      <p>
        All orders placed by us are executed through the following venues/third
        parties:
      </p>
      <p>
        Throgmorton Capital Management Limited (TCM) has agreements with the
        Zurich Intermediary Platform and with Parmenion Capital Partners LLP
        under which these companies provide TCM with an investment management
        platform and dealing services. A copy of the ‘Best Execution Policy’ for
        either the Zurich Intermediary Platform or Parmenion Capital Partners
        LLP is available upon request. Their ‘Best Execution Policy’ lists all
        execution venues available to them.
      </p>
      <p>
        We reserve the right to use another execution venue should we deem
        appropriate in accordance with our Best Execution Policy. We also
        reserve the right to execute orders outside a regulated market should we
        deem appropriate.
      </p>
      <p>
        We will regularly assess the venues/third parties available to us to
        identify those that will enable us, on a consistent basis, to obtain the
        best possible result when arranging the execution of your orders. The
        list will then be updated, where necessary, following such assessment.
      </p>
      <p>
        The third parties have responsibilities in relation to best execution
        and client order handling. We will also undertake periodic monitoring to
        ensure that they are meeting the relevant requirements.
      </p>
      <p>
        <strong>Client Specific Instructions</strong>
      </p>
      <p>
        If you have given instructions that price is not the most important
        factor in executing your instructions, we will make every effort to
        comply with your instructions but cannot guarantee this. This may be due
        to either the nature of the order, or the type of financial instrument
        you wish to trade in.
      </p>
      <p>
        We will make all decisions as to where the orders are placed in relation
        to the execution venue. We will therefore not accept specific
        instructions from clients regarding the venue where your order is
        executed.
      </p>
      <p>
        <strong>Collective investment schemes </strong>
      </p>
      <p>
        For orders in collective investment schemes e.g. Unit Trusts, OEICs and
        Investment Trusts we will place the order with the Zurich Intermediary
        Platform or with Parmenion Capital Partners LLP or directly with the
        fund manager/operator of the collective investment scheme.
      </p>
      <p>
        <strong>Charges</strong>
      </p>
      <p>
        It is our policy that commission and charging structures will not
        influence either the selection of execution venues, or the order flow
        that follows as a result of the execution process. We will therefore not
        discriminate between the execution venues we use to arrange execution of
        your orders.
      </p>
      <p>
        <strong>Monitoring and review of our execution policy</strong>
      </p>
      <p>
        We will regularly monitor the effectiveness of our best execution policy
        to identify and, where appropriate, correct any deficiencies. In
        particular, this will cover the execution quality of any third parties
        referred to in the policy.
      </p>
      <p>
        This review should be carried out regularly (on at least an annual
        basis) or whenever a material change occurs that affects your ability to
        continue to obtain the best possible result for your clients. We will
        also review this best execution policy at least annually and whenever
        there is a significant change that affects our ability to continue to
        obtain the best possible results for our clients.
      </p>
      <p>
        <strong>Staff understanding</strong>
      </p>
      <p>
        All relevant staff are made aware of this policy to highlight and
        emphasise the importance of best execution.
      </p>
    </section>
  );
}
