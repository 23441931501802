import React, { useEffect, useContext } from "react";
import "./Careers_desk.css";
import "./Careers_mob.css";
import { MobileContext } from "../App";
import PageHeader from "../wrappers/PageHeader";
import { careersImg } from "../data/imageData";

export default function Careers() {
  let isMobile = useContext(MobileContext);

  useEffect(() => {
    document.title = "Careers | Throgmorton Capital Management";
  });

  return (
    <section
      className="page-section careers-section alternate-page-section"
      id="careers"
    >
      <div className="page-col-1">
        {isMobile ? <PageHeader title="Careers" /> : null}
        <img
          className="page-img animate__animated animate__fadeIn animate__slow careers-img"
          src={careersImg}
          alt=""
        />
      </div>
      <article className="page-col-2">
        {!isMobile ? (
          <h1 className="page-header animate__animated animate__fadeInDown animate__slow">
            Careers
          </h1>
        ) : null}
        <p
          style={{
            animationDuration: "4.5s",
          }}
          className="about-text animate__animated animate__fadeIn animate__slower"
        >
          Throgmorton is growing quickly and our plan is to build our business
          into one of the country's leading wealth managers. Achieving this
          relies on us continuing to attract talented individuals to join our
          team.
        </p>
        <p
          style={{
            animationDuration: "4.5s",
          }}
          className="about-text animate__animated animate__fadeIn animate__slower"
        >
          We are therefore always keen to hear from individuals who are driven
          and client focused and are particularly interested in speaking with
          financial planners, investment managers, private bankers and financial
          planning administrators.
        </p>
        <p
          style={{
            animationDuration: "4.5s",
          }}
          className="about-text animate__animated animate__fadeIn animate__slower"
        >
          If you fit the above description and would like to have an informal
          conversation with us about working at Throgmorton then please email{" "}
          <a
            className="recruitment-link"
            href="mailto:recruitment@throgmortoncm.co.uk"
          >
            recruitment@throgmortoncm.co.uk
          </a>{" "}
          and someone will be in touch.
        </p>
      </article>
    </section>
  );
}
