import { uuid } from "uuidv4";

export const pageLinks = [
  {
    id: uuid(),
    link: "our-story",
    text: "Our Story",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "vision-and-culture",
    text: "Vision & Culture",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "what-we-do",
    text: "What We Do",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "our-team",
    text: "Our Team",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "financial-planning",
    text: "Financial Planning",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "investment-management",
    text: "Investment Management",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "media",
    text: "Media",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "literature",
    text: "Literature",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "careers",
    text: "Careers",
    className: "header-link",
  },
  {
    id: uuid(),
    link: "contact",
    text: "Contact",
    className: "header-link",
  },
];
