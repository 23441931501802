import React from "react";

import "./App.css";
import Header from "./header/Header";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import About from "./about/About";
import Contact from "./contact/Contact";
import Privacy from "./privacy/Privacy";
import Regulatory from "./regulatory/Regulatory";
import { useEffect, useState } from "react";
import logo from "./media/throgmorton-capital-logo-strapline.png";

import FinancialPlanning from "./services/FinancialPlanning";
import InvestmentManagement from "./services/InvestmentManagement";
import Notification from "./notification/Notification";
import Careers from "./careers/Careers";
import Downloads from "./downloads/Downloads";
import BestExecution from "./best-execution/BestExecution";
import Team from "./team/Team";
import Menu from "./menu/Menu";
import ClientLogin from "./client-login/ClientLogin";
import PageWrapper from "./wrappers/PageWrapper";

import { whatWeDoContent } from "./data/whatWeDoContent";
import { financialPlanningContent } from "./data/financialPlanningContent";
import { investmentManagementContent } from "./data/investmentManagementContent";

import Home from "./home/Home";
import ClientLoginPage from "./client-login/ClientLoginPage";
import CulturePage from "./culture/CulturePage";
import StoryPage from "./our-story/StoryPage";
import { visionContent } from "./data/visionContent";
import { ourStoryContent } from "./data/ourStoryData";
import MediaPage from "./media-page/MediaPage";

export const MobileContext = React.createContext(false);

function App() {
  const [acceptedCookies, setCookieAcceptance] = useState("false");
  const [isMobile, setIsMobile] = useState(null);
  const [isHome, setIsHome] = useState(false);
  const [isIE, setIsIE] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isBlueLogo, shouldBeBlueLogo] = useState(false);

  const location = useLocation();

  function determineWindowSize() {
    if (window.innerWidth > 768) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }

  useEffect(() => {
    determineWindowSize();

    if (navigator.userAgent.indexOf("Trident") > -1) {
      setIsIE(true);
      alert(
        "This website is not optimised for use in Internet Explorer. For a better experience, please use a different browser (e.g. Chrome, Edge).",
      );
    }

    let cookieConsent = window.localStorage.getItem("acceptedCookies");
    if (cookieConsent) {
      setCookieAcceptance(cookieConsent);
    }

    window.onresize = determineWindowSize;

    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    } else {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      };
    }
  }, [acceptedCookies, isHome]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }

    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  if (isIE) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <img src={logo} alt="" />
        <h1
          style={{
            color: "rgb(17, 65, 129)",
            padding: "16px",
          }}
        >
          We specialise in helping people throughout the country achieve their
          financial goals in life by providing personalised financial planning
          and expert investment management services.
        </h1>
      </div>
    );
  }
  if (isMobile === null) {
    return (
      <div className="loading-view">
        <img src={logo} alt="" />
        <div className="loader">Loading</div>
      </div>
    );
  } else {
    return (
      <div
        className="App"
        style={{
          position: isHome && !isMobile ? "fixed" : "static",
        }}
      >
        <MobileContext.Provider value={isMobile}>
          {location.search.includes("iframe=true") === false && (
            <div
              className={menuVisible ? "menu-icon cross" : "menu-icon"}
              onClick={() => {
                setMenuVisible(!menuVisible);
                // showCrossClass(!crossClass);
              }}
            >
              <svg
                id="icon"
                viewBox={
                  isMobile
                    ? "0 0 1000 1000"
                    : `0 0 1000 ${window.innerWidth > 1600 ? 750 : 750}`
                }
              >
                <path
                  className="menu-icon-path"
                  d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                  id="top"
                ></path>
                <path
                  className="menu-icon-path"
                  d="M300,320 L540,320"
                  id="middle"
                ></path>
                <path
                  className="menu-icon-path"
                  d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                  id="bottom"
                  transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                ></path>
              </svg>
            </div>
          )}
          <Header isBlueLogo={isBlueLogo} isHome={isHome} isMobile={isMobile} />
          <Menu menuVisible={menuVisible} />

          <Switch>
            <Route exact path="/privacy">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <Privacy />
              </PageWrapper>
            </Route>
            {/* <Route exact path="/regulatory">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <Regulatory />
              </PageWrapper>
            </Route> */}
            <Route exact path="/best-execution">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <BestExecution />
              </PageWrapper>
            </Route>
            <Route exact path="/vision-and-culture">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <CulturePage content={visionContent.truncated} />
              </PageWrapper>
            </Route>
            <Route exact path="/our-story">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <StoryPage content={ourStoryContent.main} />
              </PageWrapper>
            </Route>
            <Route exact path="/what-we-do">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <About content={whatWeDoContent.main} />
              </PageWrapper>
            </Route>
            <Route exact path="/our-team">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <Team />
              </PageWrapper>
            </Route>
            <Route exact path="/investment-management">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <InvestmentManagement
                  content={investmentManagementContent.main}
                  imageCardsData={investmentManagementContent.imageCards}
                />
              </PageWrapper>
            </Route>
            <Route exact path="/financial-planning">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <FinancialPlanning
                  content={financialPlanningContent.main}
                  imageCardsData={financialPlanningContent.imageCards}
                />
              </PageWrapper>
            </Route>
            <Route exact path="/careers">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <Careers />
              </PageWrapper>
            </Route>
            <Route exact path="/literature">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <Downloads />
              </PageWrapper>
            </Route>
            <Route exact path="/contact">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <Contact />
              </PageWrapper>
            </Route>
            <Route exact path="/client-login">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <ClientLoginPage />
              </PageWrapper>
            </Route>
            <Route exact path="/media">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <MediaPage />
              </PageWrapper>
            </Route>
            <Route path="/">
              <PageWrapper setMenuVisible={setMenuVisible}>
                <Home
                  setIsHome={setIsHome}
                  shouldBeBlueLogo={shouldBeBlueLogo}
                />
              </PageWrapper>
            </Route>
          </Switch>
          {acceptedCookies === "false" ? (
            <Notification setCookieAcceptance={setCookieAcceptance} />
          ) : null}
          {location.search.includes("iframe=true") === false && <ClientLogin />}
        </MobileContext.Provider>
      </div>
    );
  }
}

export default App;
