import React, { useEffect, useRef } from "react";
import "./Hero_desk.css";
import "./Hero_mob.css";
import { homepageText } from "../data/homepageText";
import HomeArrow from "../wrappers/HomeArrow";
import { MobileContext } from "../App";
import { useContext } from "react";

export default function Hero({
  setIsHome,
  slideDown,
  index,
  isHome,
  maxPages,
}) {
  const isMobile = useContext(MobileContext);
  const heroRef = useRef();

  useEffect(() => {
    document.title = "Home | Throgmorton Capital Management";
    setIsHome(true);

    return () => {
      setIsHome(false);
    };
  }, [heroRef, setIsHome]);

  return (
    <div ref={heroRef} data-index={0} id="hero" className="hero-section">
      <div data-index={0} className="hero-content-container">
        <div
          className={`hero-text-container animate__animated animate__fadeInRight animate__slow ${
            isMobile && "animate__delay-1s"
          }`}
        >
          <h4 data-index={0} className="hero-sub-header ">
            <span className="hero-title">
              WELCOME TO THROGMORTON <br /> CAPITAL MANAGEMENT
            </span>
            {homepageText}
          </h4>
        </div>
      </div>
      {isHome && !isMobile && (
        <div className="arrow-container-hero">
          <HomeArrow
            slideUp={() => {}}
            slideDown={slideDown}
            index={index}
            color="blue"
            next="what-we-do"
            type="DOWN"
          />
        </div>
      )}
    </div>
  );
}
