import React from "react";
import { forwardRef } from "react";
import { createRef } from "react";
import { useRef } from "react";
import { useState } from "react";
import { BiCopy } from "react-icons/bi";

export default function MediaLink({ id }) {
  const [showNotification, setShowNotification] = useState(false);

  const notificationRef = useRef();

  function buildUrl(id) {
    const path = `${window.location.origin}/media?video=${id}`;
    return path;
  }

  function handleShowNotification() {
    setShowNotification(true);
    notificationRef.current.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 3000,
      fill: "forwards",
      easing: "ease-in-out",
    });
    let timer = setTimeout(() => {
      setShowNotification(false);
      clearTimeout(timer);
    }, 2001);
  }

  function copyUrlToClipboard(id) {
    navigator.clipboard
      .writeText(buildUrl(id))
      .then(() => {
        handleShowNotification();
      })
      .catch((e) => console.error(e));
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <BiCopy
        style={{ cursor: "pointer" }}
        title="Click to copy URL"
        color="white"
        onClick={(e) => {
          copyUrlToClipboard(id);
          e.currentTarget.animate(
            [
              {
                transform: "scale(0.8)",
              },
              {
                transform: "scale(1)",
              },
            ],
            { duration: 300 },
          );
        }}
      />
      {/* {showNotification && <FloatingNotification ref={notificationRef} />} */}
    </div>
  );
}

const FloatingNotification = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        position: "fixed",
        bottom: 200,
        right: 200,
        top: 0,
        // left: 200,
        width: "fit-content",
        zIndex: 1,
        background: "white",
        margin: "1rem",
        borderRadius: "0.5rem",
        padding: "0.25rem 1rem",
      }}
    >
      <p
        style={{
          color: "#c2a044",
        }}
      >
        Link copied!
      </p>
    </div>
  );
});
