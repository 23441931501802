import React, { useState, useEffect } from "react";
import "./Header_mob.css";
import "./Header_desk.css";
import logoWhite from "../media/throgmorton-logo-white.png";
import logoBlue from "../media/throgmorton-capital-logo-strapline.png";
import { useLocation } from "react-router-dom";
import { MobileContext } from "../App";
import { useContext } from "react";

// import "./Header.css";
export default function Header({ isBlueLogo }) {
  const isMobile = useContext(MobileContext);

  const [scrollY, setScrollY] = useState(0);
  const [currentLogo, setCurrentLogo] = useState(logoWhite);

  const location = useLocation();

  const nonStandardPaths = ["/regulatory", "/privacy", "/best-execution"];

  useEffect(() => {
    let app = document.getElementsByClassName("App")[0];

    app.addEventListener("scroll", (e) => {
      setScrollY(e.target.scrollTop);
    });

    window.onscroll = (e) => {
      setScrollY(window.scrollY);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      switch (location.pathname) {
        case "/investment-management":
          setCurrentLogo(logoBlue);
          break;
        case "/our-team":
          setCurrentLogo(logoBlue);
          break;
        case "/client-login":
          setCurrentLogo(logoBlue);
          break;
        default:
          setCurrentLogo(logoWhite);
      }
    }

    if (!isMobile && isBlueLogo && location.pathname === "/") {
      setCurrentLogo(logoBlue);
    } else if (!isMobile && location.pathname === "/") {
      setCurrentLogo(logoWhite);
    }
  }, [location.pathname, isBlueLogo]);

  return (
    <header
      id="throg-header"
      style={
        scrollY >= 50 ||
        location.pathname === nonStandardPaths[0] ||
        location.pathname === nonStandardPaths[1] ||
        location.pathname === nonStandardPaths[2]
          ? {
              background: "#164382",
              boxShadow: "0px 1px 5px 1px rgba(33, 33, 33, 0.4)",
            }
          : {}
      }
      className="throg-header"
    >
      <>
        <a href="/">
          <img
            className="throg-logo-header"
            src={currentLogo}
            alt="Throgmorton Capital Management logo"
          />
        </a>
      </>
    </header>
  );
}
