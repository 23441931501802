import React, { useEffect, useContext, useState } from "react";

import "./StoryPage_desk.css";
import { MobileContext } from "../App";
import PageHeader from "../wrappers/PageHeader";
import { storyImg } from "../data/imageData";
import HomeLink from "../wrappers/HomeLink";
import HomeArrow from "../wrappers/HomeArrow";
import HomePagination from "../wrappers/HomePagination";

export default function StoryPage({
  setMenuVisible,
  content,
  isHome,
  isIntersected,
  index,
  slideDown,
  slideUp,
  maxPages,
}) {
  let isMobile = useContext(MobileContext);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isHome) document.title = "Our Story | Throgmorton Capital Management";

    // setIsVisible(false);

    if (isIntersected === false && !isMobile) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    if (isMobile) {
      setIsVisible(true);
    }
  }, [isHome, isIntersected]);

  return (
    <section
      className={
        isHome
          ? "page-section alternate-page-section"
          : "page-section alternate-page-section"
      }
      // id="what-we-do"
    >
      <div className="page-col-1">
        {isMobile ? <PageHeader title="Our Story" /> : null}
        <img
          data-index={index}
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
          className={
            isIntersected || !isHome
              ? "page-img story-img animate__animated animate__fadeIn animate__slow"
              : "page-img story-img"
          }
          src={storyImg}
          alt=""
        />
      </div>
      <div data-index={index} className="page-col-2">
        {!isMobile ? (
          <h1
            onAnimationStart={() => {
              setIsVisible(true);
            }}
            style={{
              visibility: isVisible ? "visible" : "hidden",
            }}
            className={
              isIntersected || !isHome
                ? "page-header animate__animated animate__fadeInDown animate__slow"
                : "page-header"
            }
          >
            Our Story
          </h1>
        ) : null}
        {content.map((data, i) => {
          if (data.type === "text") {
            return (
              <p
                style={{
                  visibility: isVisible ? "visible" : "hidden",
                  animationDuration: "4.5s",
                }}
                key={data.id}
                className={
                  isIntersected || !isHome
                    ? "about-text animate__animated animate__fadeIn animate__slower"
                    : "about-text"
                }
              >
                {data.content}
              </p>
            );
          }
        })}

        {isHome && (
          <div>
            <HomeLink
              isIntersected={isIntersected}
              isVisible={isVisible}
              pathname="/our-story"
            />
          </div>
        )}
        {isHome && !isMobile && (
          <div className="arrow-container-page">
            <HomeArrow
              slideUp={slideUp}
              index={index}
              color="blue"
              previous="hero"
              type="UP"
            />
            <HomePagination page={index} maxPages={maxPages} />
            <HomeArrow
              slideDown={slideDown}
              index={index}
              color="blue"
              next="financial-planning"
              type="DOWN"
            />
          </div>
        )}
      </div>
    </section>
  );
}
