import { uuid } from "uuidv4";

export const visionContent = {
  main: [
    {
      id: uuid(),
      type: "text",
      content:
        "We pride ourselves on long term thinking and aim to make decisions which benefit our clients, employees and everyone else we interact with.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "To grow our business successfully we must embrace a vision and culture that encourages our people to grow, develop and prosper in their career, thereby equipping them to provide the best possible outcomes for our clients.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "Below are some key points and areas that we believe are paramount to ensuring we stay true to the vision and culture we wish to achieve for Throgmorton Capital Management.",
    },
  ],
  truncated: [
    {
      id: uuid(),
      type: "text",
      content:
        "We pride ourselves on long term thinking and aim to make decisions which benefit our clients, employees and everyone else we interact with.",
    },
    {
      id: uuid(),
      type: "text",
      content:
        "To grow our business successfully we must embrace a vision and culture that encourages our people to grow, develop and prosper in their career, thereby equipping them to provide the best possible outcomes for our clients.",
    },
  ],
};
