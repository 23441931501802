import React, { useState, useEffect } from "react";

import "./ContactForm_desk.css";
import "./ContactForm_mob.css";

export default function ContactForm() {
  const ENDPOINT1 =
    "https://europe-west2-throgmortoncapital.cloudfunctions.net/contactThrogmorton";

  const [errorStatus, setErrorStatus] = useState({
    hasError: false,
    message: "",
  });

  const [submitBtnStatus, setSubmitBtnStatus] = useState({
    disabled: false,
    text: "Submit",
  });

  useEffect(() => {
    let lastSubmit = localStorage.getItem("lastSubmit");

    if (lastSubmit) {
      if (Date.now() - Number(lastSubmit) < 60000) {
        setSubmitBtnStatus({
          disabled: true,
          text: "Submitted",
        });
      }
    }
  }, []);

  const handleContactSubmit = (e) => {
    e.preventDefault();

    let lastSubmit = localStorage.getItem("lastSubmit");

    if (lastSubmit) {
      if (Date.now() - Number(lastSubmit) < 60000) {
        setSubmitBtnStatus({
          disabled: true,
          text: "Submitted",
        });
        setErrorStatus({
          hasError: true,
          message:
            "You've recently submitted a contact request. Please wait and try again.",
        });
      }
    }

    setSubmitBtnStatus({
      disabled: true,
      text: "Submitting...",
    });

    const fName = e.target.elements.fullName.value,
      email = e.target.elements.email.value,
      tel = e.target.elements.tel.value,
      message = e.target.elements.message.value;

    if (!fName || !email || !tel) {
      // throw new Error("insufficient contact details");
      // alert("Please fill in all required details");

      setErrorStatus({
        hasError: true,
        message:
          "Please fill in all required fields (Name, Email and Telephone Number)",
      });
      setSubmitBtnStatus({
        disabled: false,
        text: "Submit",
      });
      return;
    }

    fetch(ENDPOINT1, {
      // mode: "no-cors",
      method: "POST",
      body: JSON.stringify({
        fName: fName,
        lName: "IGNORE",
        email: email,
        tel: tel,
        message: message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          localStorage.setItem("lastSubmit", Date.now());
          setSubmitBtnStatus({
            disabled: true,
            text: "Submitted",
          });
          setTimeout(() => {
            setSubmitBtnStatus({
              disabled: false,
              text: "Submit",
            });
          }, 60000);
        } else if (data.success === false) {
          alert("Error submitting contact request");
        }
      })
      .catch((e) => {
        setSubmitBtnStatus({
          disabled: false,
          text: "Submit",
        });
        setErrorStatus({
          hasError: true,
          message:
            "There was an error submitting your request. Please try again.",
        });
      });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleContactSubmit} className="contact-form" action="">
        <div>
          {/* <label className="contact-form-label" htmlFor="">
            First Name
          </label> */}
          <input
            placeholder="Name*"
            className="contact-form-input"
            type="text"
            name="fullName"
          />
        </div>

        <div>
          {/* <label className="contact-form-label" htmlFor="">
            Telephone
          </label> */}
          <input
            placeholder="Telephone*"
            className="contact-form-input"
            type="tel"
            name="tel"
          />
        </div>
        <div>
          {/* <label className="contact-form-label" htmlFor="">
            Email
          </label> */}
          <input
            placeholder="Email*"
            className="contact-form-input"
            type="email"
            name="email"
          />
        </div>
        <div>
          {/* <label className="contact-form-label" htmlFor="">
            Message
          </label> */}
          <textarea
            placeholder="Enter a message..."
            className="contact-form-text-area"
            name="message"
            id=""
          ></textarea>
        </div>
        <div>
          <button
            disabled={submitBtnStatus.disabled}
            className="contact-form-btn"
          >
            {submitBtnStatus.text}
          </button>
        </div>
        {errorStatus.hasError && (
          <div className="error-msg-container">
            <p>{errorStatus.message}</p>
          </div>
        )}
      </form>
    </div>
  );
}
