import { uuid } from "uuidv4";
import { bpsBrochure, mpsBrochure } from "./brochureData";
import { bpsImg, mpsImg } from "./imageData";

export const investmentManagementContent = {
  main: [
    {
      id: uuid(),
      type: "text",
      content:
        "Throgmorton Capital Management offer our clients two discretionary investment management solutions. The engine that powers our clients’ financial plans are the company’s investment management services. Brochures for our Managed Portfolio Service and Bespoke Portfolio Service, which are the firm's two discretionary investment management services, can be found below.",
    },
  ],
  truncated: [
    {
      id: uuid(),
      type: "text",
      content:
        "Throgmorton Capital Management offer our clients two discretionary investment management solutions. The engine that powers our clients’ financial plans are the company’s investment management services. Brochures for our Managed Portfolio Service and Bespoke Portfolio Service, which are the firm's two discretionary investment management services, can be found by clicking discover more.",
    },
  ],
  imageCards: [
    {
      id: uuid(),
      title: "Managed Portfolio Service (MPS)",
      text: [
        "A model based low contact approach where individual assets within each risk profile are the same for all clients invested.",
      ],
      img: mpsImg,
      linkText: "Download our MPS Brochure",
      link: mpsBrochure,
    },
    {
      id: uuid(),
      title: "Bespoke Portfolio Service (BPS)",
      text: [
        "High service level providing solutions tailored to each client’s requirements. Clients using this service have access to investment managers to discuss investment strategy and portfolio positioning.",
      ],
      img: bpsImg,
      linkText: "Download our BPS Brochure",
      link: bpsBrochure,
    },
  ],
};
