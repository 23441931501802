import React, { useEffect, useContext, useState, useRef } from "react";

import "./CulturePage_desk.css";
import { MobileContext } from "../App";
import PageHeader from "../wrappers/PageHeader";
import { visionImg } from "../data/imageData";
import HomeLink from "../wrappers/HomeLink";
import HomeArrow from "../wrappers/HomeArrow";
import HomePagination from "../wrappers/HomePagination";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  CarouselContext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function CulturePage({
  setMenuVisible,
  content,
  isHome,
  isIntersected,
  index,
  slideDown,
  slideUp,
  maxPages,
}) {
  let isMobile = useContext(MobileContext);

  const nextRef = useRef(null);
  const backRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const carouselContext = useContext(CarouselContext);

  useEffect(() => {}, [carouselContext]);

  useEffect(() => {
    if (!isHome)
      document.title = "Vision & Culture | Throgmorton Capital Management";

    if (isIntersected === false && !isMobile) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    if (isMobile) {
      setIsVisible(true);
    }
  }, [isHome, isIntersected, isMobile]);

  return (
    <section className={isHome ? "page-section" : "page-section"}>
      <div className="page-col-1">
        {isMobile ? <PageHeader title="Vision & Culture" /> : null}
        <img
          data-index={index}
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
          className={
            isIntersected || !isHome
              ? "page-img culture-img animate__animated animate__fadeIn animate__slow"
              : "page-img culture-img"
          }
          src={visionImg}
          alt=""
        />
      </div>
      <div data-index={index} className="page-col-2">
        {!isMobile ? (
          <h1
            onAnimationStart={() => {
              setIsVisible(true);
            }}
            style={{
              visibility: isVisible ? "visible" : "hidden",
            }}
            className={
              isIntersected || !isHome
                ? "page-header animate__animated animate__fadeInDown animate__slow"
                : "page-header"
            }
          >
            Vision & Culture
          </h1>
        ) : null}

        {content.map((data, i) => {
          if (data.type === "text") {
            return (
              <p
                key={data.id}
                style={{
                  visibility: isVisible ? "visible" : "hidden",
                  animationDuration: "4.5s",
                }}
                className={
                  isIntersected || !isHome
                    ? "about-text animate__animated animate__fadeIn animate__slower"
                    : "about-text"
                }
              >
                {data.content}
              </p>
            );
          } else {
            return null;
          }
        })}
        {!isHome && !isMobile && (
          <>
            <CarouselProvider
              naturalSlideWidth={500}
              naturalSlideHeight={250}
              totalSlides={2}
              isPlaying={true}
              infinite={true}
              dragEnabled={false}
              interval={12000}
            >
              <Slider>
                <Slide index={0}>
                  <div className="vc-list-container vc-list-container-1 animate__animated animate__fadeIn animate__slower">
                    <h2 className="vc-title">Our Vision</h2>
                    <ul className="vc-list vc-list-1">
                      <li>
                        To create a brand that is widely recognised for
                        providing high-quality, impartial advice.
                      </li>
                      <li>
                        To have a range of market-leading discretionary
                        investment solutions.
                      </li>
                      <li>
                        To have a positive impact on everyone that we interact
                        with.
                      </li>
                      <li>
                        To be the &ldquo;Employer of Choice&rdquo; for financial
                        planners, investment managers and our support staff.
                      </li>
                      <li>
                        To be an innovator in everything that we do, and to
                        continue developing new services to meet our client
                        evolving needs.
                      </li>
                    </ul>
                  </div>
                </Slide>
                <Slide index={1}>
                  <div className="vc-list-container animate__animated animate__fadeIn animate__slower">
                    <h2 className="vc-title">Our Culture</h2>
                    <ul className="vc-list vc-list-2">
                      <li>
                        Love our clients. They are the reason we are in
                        business. We should be worrying about them, spending
                        time with them, and obsessing over how we can serve them
                        better.
                      </li>
                      <li>
                        Say &ldquo;We,&rdquo; not &ldquo;I&rdquo;. We serve our
                        clients better by leveraging the whole pool of knowledge
                        and experience that exists within the company.
                      </li>
                      <li>
                        Have high standards. We aim to continually improve all
                        of the services that we offer, and be at the vanguard of
                        our industry. We should exceed client expectations, and
                        always be willing to go the extra mile to serve them.
                      </li>
                      <li>
                        Think long-term. We run the company with a very long
                        time horizon and we therefore build long-term
                        relationships with our clients, look after our staff,
                        and aim to have a positive impact on the communities in
                        which we operate.
                      </li>
                      <li>
                        Keep some perspective. We try not to take ourselves, or
                        the firm, too seriously. Family and friends should come
                        first. The firm shouldn&rsquo;t be full of workaholics,
                        staff should use their holidays and enjoy life away from
                        the office as well as serving our clients.
                      </li>
                    </ul>
                  </div>
                </Slide>
              </Slider>
              <ButtonBack
                style={{
                  visibility: "hidden",
                }}
              >
                <button ref={backRef}>back</button>
              </ButtonBack>
              <ButtonNext
                style={{
                  visibility: "hidden",
                }}
              >
                <button ref={nextRef}>next</button>
              </ButtonNext>
              <CarouselNavWrapper />
            </CarouselProvider>
          </>
        )}
        {isMobile && !isHome && (
          <div className="vision-culture-content-container">
            <div className="vc-list-container animate__animated animate__fadeIn animate__slower">
              <h2 className="vc-title">Our Vision</h2>
              <ul className="vc-list vc-list-1">
                <li>
                  To create a brand that is widely recognised for providing
                  high-quality, impartial advice.
                </li>
                <li>
                  To have a range of market-leading discretionary investment
                  solutions.
                </li>
                <li>
                  To have a positive impact on everyone that we interact with.
                </li>
                <li>
                  To be the &ldquo;Employer of Choice&rdquo; for financial
                  planners, investment managers and our support staff.
                </li>
                <li>
                  To be an innovator in everything that we do, and to continue
                  developing new services to meet our client evolving needs.
                </li>
              </ul>
            </div>
            <div className="vc-list-container animate__animated animate__fadeIn animate__slower">
              <h2 className="vc-title">Our Culture</h2>
              <ul className="vc-list vc-list-2">
                <li>
                  Love our clients. They are the reason we are in business. We
                  should be worrying about them, spending time with them, and
                  obsessing over how we can serve them better.
                </li>
                <li>
                  Say &ldquo;We,&rdquo; not &ldquo;I&rdquo;. We serve our
                  clients better by leveraging the whole pool of knowledge and
                  experience that exists within the company.
                </li>
                <li>
                  Have high standards. We aim to continually improve all of the
                  services that we offer, and be at the vanguard of our
                  industry. We should exceed client expectations, and always be
                  willing to go the extra mile to serve them.
                </li>
                <li>
                  Think long-term. We run the company with a very long time
                  horizon and we therefore build long-term relationships with
                  our clients, look after our staff, and aim to have a positive
                  impact on the communities in which we operate.
                </li>
                <li>
                  Keep some perspective. We try not to take ourselves, or the
                  firm, too seriously. Family and friends should come first. The
                  firm shouldn't be full of workaholics, staff should use their
                  holidays and enjoy life away from the office as well as
                  serving our clients.
                </li>
              </ul>
            </div>
          </div>
        )}
        {isHome && (
          <div>
            <HomeLink
              isIntersected={isIntersected}
              isVisible={isVisible}
              pathname="/vision-and-culture"
            />
          </div>
        )}
        {isHome && !isMobile && (
          <div className="arrow-container-page">
            <HomeArrow
              slideUp={slideUp}
              index={index}
              color="gold"
              previous="hero"
              type="UP"
            />

            <HomePagination page={index} maxPages={maxPages} />
            <HomeArrow
              slideDown={slideDown}
              index={index}
              color="gold"
              next="financial-planning"
              type="DOWN"
            />
          </div>
        )}
      </div>
    </section>
  );
}

function CarouselNavWrapper() {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <div className="arrow-container-horizontal">
      <HomeArrow
        color="gold"
        previous="hero"
        type="LEFT"
        action={() => {
          if (currentSlide === 0) {
            return;
          } else {
            carouselContext.setStoreState({ currentSlide: 0 });
          }
        }}
      />

      <HomePagination page={currentSlide + 1} maxPages={2} horizontal={true} />
      <HomeArrow
        color="gold"
        next="financial-planning"
        type="RIGHT"
        action={() => {
          if (currentSlide === 1) {
            return;
          } else {
            carouselContext.setStoreState({ currentSlide: 1 });
          }
        }}
      />
    </div>
  );
}
