import React, { useEffect, useContext, useState } from "react";

// import "./About_mob.css";
// import "./About_desk.css";
import "./media.css";
import { MobileContext } from "../App";
import PageHeader from "../wrappers/PageHeader";
import { mediaImg } from "../data/imageData";
import MediaLink from "./MediaLink";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import HomeArrow from "../wrappers/HomeArrow";
import HomePagination from "../wrappers/HomePagination";
import {
  CarouselProvider,
  Slider,
  Slide,
  CarouselContext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function MediaPage({ isHome, isIntersected, index }) {
  const nextRef = useRef(null);
  const backRef = useRef(null);

  let isMobile = useContext(MobileContext);

  const location = useLocation();

  const [videos, setVideos] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showFilePopup, setShowFilePopup] = useState(false);
  const [fileSrc, setFileSrc] = useState("");
  const [searchedVideo, setSearchedVideo] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    if (!isHome) document.title = "Media | Throgmorton Capital Management";

    if (isIntersected === false && !isMobile) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    if (isMobile) {
      setIsVisible(true);
    }

    fetch(
      "https://europe-west2-throgmortoncapital.cloudfunctions.net/getVideos",
    )
      .then((res) => res.json())
      .then((result) => {
        setVideos(result.videos);
      });
  }, [isHome, isIntersected]);

  useEffect(() => {
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    const videoId = searchParams.get("video");
    let foundVideo = videos.findIndex((val) => {
      return videoId === val.id;
    });
    setSearchedVideo({
      id: videoId,
      index: foundVideo,
    });
    if (foundVideo >= 0) {
      setCarouselIndex(foundVideo);
    }

    if (isMobile) {
      let videoEl = document.getElementById(videoId);
      if (videoEl) {
        videoEl.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [location.search, videos, isMobile]);

  return (
    <section
      className={isHome ? "page-section" : "page-section"}
      id="what-we-do"
    >
      <div className="page-col-1">
        {isMobile ? <PageHeader title="Media" /> : null}
        <img
          data-index={index}
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
          className={
            isIntersected || !isHome
              ? "page-img animate__animated animate__fadeIn animate__slow"
              : "page-img"
          }
          src={mediaImg}
          alt=""
        />
      </div>
      <div data-index={index} className="page-col-2">
        {!isMobile ? (
          <h1
            onAnimationStart={() => {
              setIsVisible(true);
            }}
            style={{
              visibility: isVisible ? "visible" : "hidden",
            }}
            className={
              isIntersected || !isHome
                ? "page-header animate__animated animate__fadeInDown animate__slow"
                : "page-header"
            }
          >
            Media
          </h1>
        ) : null}
        {!isMobile && videos.length > 0 && (
          <CarouselProvider
            naturalSlideWidth={500}
            naturalSlideHeight={250}
            totalSlides={videos.length}
            isPlaying={false}
            infinite={true}
            dragEnabled={false}
            interval={12000}
            isIntrinsicHeight={true}
            currentSlide={carouselIndex}
          >
            <Slider>
              {videos.map((data, i) => {
                return (
                  <Slide key={data.title} index={i}>
                    <VideoCard
                      setFileSrc={setFileSrc}
                      canShowPopup={false}
                      setShowFilePopup={setShowFilePopup}
                      searchedVideo={searchedVideo}
                      isMobile={isMobile}
                      {...data}
                    />
                  </Slide>
                );
              })}
            </Slider>
            <CarouselNavWrapper
              maxPages={videos.length}
              searchedVideo={searchedVideo}
              carouselIndex={carouselIndex}
            />
          </CarouselProvider>
        )}
        {isMobile && videos.length > 0 && (
          <div className="media-container">
            {videos.map((data, i) => {
              return (
                <VideoCard
                  searchedVideo={searchedVideo}
                  setShowFilePopup={setShowFilePopup}
                  key={i}
                  {...data}
                />
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}

function VideoCard({
  title,
  content,
  createdAt,
  id,
  searchedVideo,
  isMobile,
  image,
}) {
  const videoRef = useRef();

  useEffect(() => {
    if (searchedVideo?.id === id && isMobile) {
      document.getElementById(id).scrollIntoView({
        block: "center",
      });
    }
  }, [searchedVideo, id, isMobile]);

  return (
    <div
      id={id}
      className={`video-card ${
        searchedVideo?.id === id && "highlighted-video"
      }`}
    >
      <div>
        <p className="video-title">{title}</p>
      </div>
      <video
        poster={image.url}
        preload="auto"
        controls
        className="media-video"
        src={content.url}
        ref={videoRef}
      ></video>
      <div className="video-subtitle">
        <p className="video-date">
          Uploaded on{" "}
          {new Intl.DateTimeFormat("en-GB", { dateStyle: "long" }).format(
            new Date(createdAt),
          )}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          <label style={{ color: "white" }} htmlFor="">
            Copy URL
          </label>
          <MediaLink id={id} />
        </div>
      </div>
    </div>
  );
}

function CarouselNavWrapper({ maxPages, searchedVideo, carouselIndex }) {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    function onChange() {
      console.log("changing state");
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <div
      style={{
        marginTop: "2rem",
      }}
      className="arrow-container-horizontal"
    >
      <HomeArrow
        color="gold"
        previous="hero"
        type="LEFT"
        action={() => {
          console.log(carouselContext.state.currentSlide);
          if (carouselContext.state.currentSlide === 0) {
            carouselContext.setStoreState({
              currentSlide: carouselContext.state.totalSlides - 1,
            });
          } else {
            carouselContext.setStoreState({
              currentSlide: currentSlide - 1,
            });
          }
        }}
      />

      <HomePagination
        page={currentSlide + 1}
        maxPages={maxPages || 1}
        horizontal={true}
      />
      <HomeArrow
        color="gold"
        next="financial-planning"
        type="RIGHT"
        action={() => {
          console.log(
            carouselContext.state.currentSlide,
            carouselContext.getStoreState().currentSlide,
            carouselContext.state.totalSlides,
          );
          if (
            carouselContext.state.currentSlide ===
            carouselContext.state.totalSlides - 1
          ) {
            carouselContext.setStoreState({ currentSlide: 0 });
          } else {
            carouselContext.setStoreState({
              currentSlide: carouselContext.state.currentSlide + 1,
            });
          }
        }}
      />
    </div>
  );
}
