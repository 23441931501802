import React from "react";

export default function HomePagination({
  page,
  maxPages,
  color,
  horizontal = false,
}) {
  console.log(maxPages);

  return (
    <div
      className={`pagination-container ${
        horizontal ? "pagination-container-horizontal" : ""
      } `}
    >
      <div className="pagination-item-1">
        <span
          className="pagination-item"
          style={{
            color:
              color === "blue" ? "var(--primary-blue)" : "var(--primary-gold)",
          }}
        >
          {page}
        </span>
      </div>
      <div className="pagination-item-2">
        <span
          style={{
            color:
              color === "blue" ? "var(--primary-blue)" : "var(--primary-gold)",
          }}
          className="pagination-item pagination-separator"
        >
          /
        </span>
      </div>
      <div className="pagination-item-3">
        <span
          className="pagination-item"
          style={{
            color:
              color === "blue" ? "var(--primary-blue)" : "var(--primary-gold)",
          }}
        >
          {maxPages}
        </span>
      </div>
    </div>
  );
}
