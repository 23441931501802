import React from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { MobileContext } from "../App";
import "./ImageCard.css";

export default function ImageCard({
  title,
  content = [],
  link,
  linkTitle,
  imgUrl,
  customAction,
}) {
  const location = useLocation();
  const isMobile = useContext(MobileContext);

  let path = location.pathname;

  return (
    <div
      className={`image-card-container ${
        path === "/financial-planning" ? "fp-img-card-container" : ""
      } animate__animated animate__fadeIn animate__slower`}
    >
      <div className="image-card-img-cont">
        <img className="image-card-img" src={imgUrl} alt="" />
      </div>
      <div className="image-card-main">
        <h4 className="image-card-title">{title}</h4>
        {content.map((data, i) => {
          return <p className="image-card-text">{data}</p>;
        })}
      </div>
      <div className="image-card-link-cont">
        <a
          onClick={isMobile ? null : (e) => customAction(e, link)}
          target="_blank"
          className="image-card-link"
          href={link}
        >
          {linkTitle}
        </a>
      </div>
    </div>
  );
}
