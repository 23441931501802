import { uuid } from "uuidv4";

export const brochureData = [
  {
    id: uuid(),
    link: "https://storage.googleapis.com/throgmortoncm-files/brochures/Financial%20Planning%20Brochure.pdf",
    title: "Financial Planning Brochure",
  },
  {
    id: uuid(),
    link: "https://media.graphassets.com/3S3aKAzHRuOWk0INFRLV",
    title: "Managed Portfolio Service Brochure",
  },
  {
    id: uuid(),
    link: "https://media.graphassets.com/vrQqr7ECRe2doF62nw7N",
    title: "Bespoke Portfolio Service Brochure",
  },
];

const brochures = fetch(
  "https://europe-west2-throgmortoncapital.cloudfunctions.net/getBrochures",
)
  .then((res) => res.json())
  .then((data) => {
    return data.brochures;
  })
  .catch((e) => console.log);

let mpsUrl = "";
let bpsUrl = "";

for (let data in brochures) {
  if (data.title === "Managed Portfolio Service Brochure") {
    mpsUrl = data.document.url;
  }

  if (data.title === "Bespoke Portfolio Service Brochure") {
    bpsUrl = data.document.url;
  }
}

export const mpsBrochure = brochureData[1].link;
export const bpsBrochure = brochureData[2].link;
