import React from "react";
import { Link } from "react-router-dom";

export default function HomeLink({
  pathname,
  text = "Discover more",
  isIntersected,
  isVisible,
}) {
  return (
    <Link
      onClick={() => {
        window.scrollTo({
          top: 0,
        });
      }}
      to={{
        pathname: pathname,
        state: {
          hideMenu: true,
        },
      }}
      style={{
        visibility: isVisible ? "visible" : "hidden",
      }}
      className={
        isIntersected
          ? "discover-btn animate__animated animate__fadeIn"
          : "discover-btn"
      }
    >
      {text}
    </Link>
  );
}
