import React, { useEffect, useContext, useState } from "react";
import "./Contact_desk.css";
import "./Contact_mob.css";

import { MobileContext } from "../App";
import ContactForm from "./ContactForm";
import PageHeader from "../wrappers/PageHeader";
import { contactImg } from "../data/imageData";
import HomeArrow from "../wrappers/HomeArrow";
import HomePagination from "../wrappers/HomePagination";
import linkedin2 from "../media/linkedin2.svg";
import Location from "./Location";
import { locationData } from "../data/locationData";

import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyD2ZZc-KV2yT2Tu1AJqAYsYZoMZXzaLpm0",
  version: "weekly",
  libraries: ["places"],
});

const manchester = { lat: 53.41041323576324, lng: -2.217746779626635 };

const mapOptions = {
  center: manchester,
  zoom: 15,
};

export default function Contact({
  isHome,
  index,
  slideUp,
  isIntersected,
  maxPages,
}) {
  let isMobile = useContext(MobileContext);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isHome) document.title = "Contact | Throgmorton Capital Management";

    if (isIntersected === false && !isMobile) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    if (isMobile) {
      setIsVisible(true);
    }

    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(
          document.getElementById("map"),
          mapOptions,
        );
        const marker = new google.maps.Marker({
          position: manchester,
          map: map,
          animation: google.maps.Animation.DROP,
        });
      })
      .catch((e) => {
        // do something
        console.error(e);
      });
  }, [isHome, isIntersected, isMobile]);

  return (
    <section className="page-section" id="contact">
      <div className="page-col-1">
        {isMobile ? <PageHeader title="Contact Us" /> : null}

        <img
          data-index={index}
          src={contactImg}
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
          alt=""
          className={
            isIntersected || !isHome
              ? "page-img animate__animated animate__fadeIn animate__slow"
              : "page-img"
          }
        />
      </div>

      <div data-index={index} className="page-col-2">
        {!isMobile ? (
          <div>
            <h1
              onAnimationStart={() => {
                setIsVisible(true);
              }}
              style={{
                visibility: isVisible ? "visible" : "hidden",
              }}
              className={
                isIntersected || !isHome
                  ? "page-header animate__animated animate__fadeInDown animate__slow"
                  : "page-header"
              }
            >
              Contact Us
            </h1>
          </div>
        ) : null}

        <div className="contact-content-container">
          {!isMobile && <div id="map"></div>}
          <ContactForm />

          {/* <div id="map"></div> */}
          <div className="contact-links">
            <h3 className="locations-header">Location</h3>
            {locationData.map((l, i) => {
              return (
                <Location
                  isHome={isHome}
                  isIntersected={isIntersected}
                  isVisible={isVisible}
                  isMobile={isMobile}
                  addrLine1={l.addrLine1}
                  addrLine2={l.addrLine2}
                  addrLine3={l.addrLine3}
                  addrLine4={l.addrLine4}
                  city={l.city}
                  linkedin={linkedin2}
                  telephone={l.telephone}
                  showEmailandTel={true}
                  hideLinkedIn={l.hideLinkedIn}
                />
              );
            })}
          </div>
          {/* {isHome && isMobile ? null : null} */}
          {isMobile && !isHome && (
            <div className="map-wrapper">
              <div id="map"></div>
            </div>
          )}
          {isHome && !isMobile && (
            <div className="arrow-container-page">
              <HomeArrow
                slideUp={slideUp}
                index={index}
                color="gold"
                previous="investment-management"
                type="UP"
              />
              <HomePagination page={index} maxPages={maxPages} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
