import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function PageWrapper({ setMenuVisible, children }) {
  let location = useLocation();

  useEffect(() => {
    if (location.state) {
      setMenuVisible(!location.state.hideMenu);
    }
  }, [location, setMenuVisible]);

  return <>{children}</>;
}
